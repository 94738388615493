import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBSpinner} from 'mdbreact';
import {useHistory} from 'react-router-dom'

import stackd from '../../api/stackd.api';

import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";
import CloseIcon from '@material-ui/icons/Close';

export default function CompanyRow({company, taList}){
    const [contactDecision, setContactDecision] = useState(null);
    const history = useHistory()

    useEffect(()=>{
        stackd.get( `/contacts/${company.contactIdDecision}`)
            .then(response=>response.data)
            .then((contact)=>{
                setContactDecision(contact)
            })
    },[company])

    const showTA = () => {
        history.push(`/company/view/${company.companyId}`, {company: company});
    }

    let contactDecisionHTML = ""
    if(contactDecision === null){
        contactDecisionHTML = <MDBSpinner/>
    } else {
        contactDecisionHTML = <span>{contactDecision.firstname} {contactDecision.lastname} - {contactDecision.position}</span>
    }

    let stateBadge = (
        <MDBBadge color="light" style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
            <span style={{marginTop: "2px", fontSize: "1.3em"}}>Non déclarée</span>
            <CloseIcon style={{verticalAlign: "sub", color: "#000000", fontSize: "1rem", marginLeft: "0.15rem"}}/>
        </MDBBadge>
    )
    let amount = 0

    if(taList.length > 0) {
        taList.sort((item1, item2) => item2.dateTime - item1.dateTime);
        const icon = taList[0].state === "Versée" ?
            <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
            <TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

        stateBadge = (
            <MDBBadge color={taList[0].state === "Versée" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
                <span style={{marginTop: "2px", fontSize: "1.3em"}}>{taList[0].state}</span> {icon}
            </MDBBadge>
        )

        amount = taList[0].amount
    }

    return(
        <tr onClick={showTA} className="tr-hover">
            <td>{company.companyName}</td>
            <td>{contactDecisionHTML}</td>
            <td>{contactDecision === null ? contactDecisionHTML : contactDecision.email}</td>
            <td>{contactDecision === null ? contactDecisionHTML : contactDecision.phoneNumber}</td>
            <td>{stateBadge}</td>
            <td>{amount}</td>
        </tr>
    )
}