import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom'

import {MDBBadge, MDBIcon} from 'mdbreact';

import {Button, Typography} from "@material-ui/core";
import StackdDataContext from "../../context/StackdDataContext";
import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";

const options = {year: 'numeric', month: 'long', day: 'numeric'};

export default function TARow({ta}){
    const history = useHistory()

    const showTA = () => {
        history.push(`/ta/view/${ta.taId}`, {ta: ta});
    }

    const icon = ta.state === "Versée" ?
        <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
        <TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

    return(
        <tr onClick={showTA} className="tr-hover">
            <td className={ta.state === "Versée" ? "ta-validate": ""}>
                <MDBBadge color={ta.state === "Versée" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
                    <span style={{marginTop: "2px", fontSize: "1.3em"}}>{ta.state}</span> {icon}
                </MDBBadge>
            </td>
            <td>
                <div className="d-block" style={{maxWidth: "15vw"}}>
                    <Typography noWrap={true}>
                        {ta.companyName}
                    </Typography>
                </div>
            </td>
            <td>{ta.amount} €</td>
            <td>{ta.paymentType}</td>
            <td>{new Date(Number(ta.dateTime)).toLocaleDateString('fr-FR', options)}</td>
            <ActionTd ta={ta}/>
        </tr>
    );
}

const ActionTd = ({ta}) => {
    const {deleteTA} = useContext(StackdDataContext)

    return(
        <td style={{textAlign: "center"}} className="btn-action">
            <Button color="secondary" size="medium" onClick={deleteTA(ta.taId)}>
                <MDBIcon icon="trash-alt" className="mr-2"/>
                Supprimer
            </Button>
        </td>
    )
}
