import React from 'react'

const ErrorBox = (error) => {
	return(
		<div>
			Error : {error}
		</div>
	)
}

export default ErrorBox