import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stackd from "../../api/stackd.api";
import Alert from "../../utils/Alert";
import StackdDataContext from "../../context/StackdDataContext";
import ToastContext from "../../context/ToastContext";

export default function DepartmentFormDialog({open, department, editMode, handleClose}) {
	const [departmentName, setDepartmentName] = useState(editMode ? department.departmentName : "")
	const [isValid, setIsValid] = useState(false)

	const {toast} = useContext(ToastContext);
	const {addDepartment, editDepartment} = useContext(StackdDataContext)

	const handleSubmit = async () => {
		let departmentData = {
			departmentName: departmentName,
			address : "",
			postalCode : "",
			city : "",
			numberUAI : "",
		};

		const departmentChanged = editMode ? {
			departmentId: department.departmentId,
			organisationId: department.organisationId,
			contactId: department.contactId
		} : {
			departmentId: "",
			organisationId: sessionStorage.getItem("resId"),
			contactId: ""
		}
		departmentData = {...departmentData, ...departmentChanged}

		try {
			let promiseDepartment = editMode ? stackd.patch(`/departments/${departmentData.departmentId}`, departmentData) : stackd.post("/departments", departmentData)

			const {departmentId} = await promiseDepartment.then(response=>response.data)

			if(editMode) {
				editDepartment(departmentData)
				toast.fire({icon: "success", title: `Structure modifiée`})
				handleClose()
				return
			}

			await stackd.post(`/formations/`,{
				formationName : "defaut",
				divisionName : "non-spécifié",
				numberRNCP: "non-spécifié",
				departmentId : departmentId
			})

			addDepartment({...departmentData, departmentId: departmentId});
			toast.fire({icon: "success", title: `Structure ajoutée`})
			handleClose()
		} catch (error){
			Alert.error(error)
		}
	}

	useEffect(()=>{
		let localValid = departmentName !== "";

		if(editMode){
			localValid = localValid && departmentName !== department.departmentName
		}

		setIsValid(localValid)
	}, [departmentName, department, editMode])

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{editMode ? "Editer" : "Ajouter"} une structure</DialogTitle>
			<DialogContent>
				<TextField
					onChange={(event)=>{setDepartmentName(event.target.value)}}
					value={departmentName}
					autoFocus
					margin="dense"
					label="Nom de la structure"
					type="text"
					fullWidth
					id="input-department-name"
					className="mb-3"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid}>
					{editMode ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}