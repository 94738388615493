import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stackd from "../../api/stackd.api";
import Alert from "../../utils/Alert";
import ToastContext from "../../context/ToastContext";
import ImageInputPreviewer from "../ImageInputPreviewer";

import PreviewImage from "../../assets/img/image-preview.png"

export default function AccountFormDialog({open, handleClose}) {
	const [emailAccount, setEmailAccount] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState( "")
	const [repassword, setRepassword] = useState("")

	const [signature, setSignature] = useState(PreviewImage)
	const [signatureFile, setSignatureFile] = useState(null)

	useEffect(()=>{
		stackd.get(`/accounts/${sessionStorage.getItem("accountId")}`)
			.then(response => response.data)
			.then(accountData => {
				setEmail(accountData.email)
				setEmailAccount(accountData.email)
				setSignature(`${stackd.defaults.baseURL.slice(0,stackd.defaults.baseURL.length-1)}${accountData.uriSignature}`)
			})
	}, [])

	const {toast} = useContext(ToastContext);

	const handleSubmit = () => {
		let accountData = {
			email: email,
			permission: sessionStorage.getItem("permission"),
			resId: sessionStorage.getItem("resId")
		};

		if(password !== "")
			accountData["password"] = password;

		let promisesPatch = [stackd.patch(`/accounts/${sessionStorage.getItem("accountId")}`, accountData)]
		if (sessionStorage.getItem("permission") !== "Composante" && signatureFile !== null)
			promisesPatch.push(
				stackd.patch(`/accounts/${sessionStorage.getItem("accountId")}/uploadSignature`, new FormData().append("fileSignature", signature), {
				"Content-Type": "multipart/form-data"
			})
			)

		Promise.all(promisesPatch).then(()=>{
			toast.fire({icon: "success", title: "Compte modifiée"})
			handleClose()
		}).catch(Alert.error)
	}

	const isValid = () => {
		return email !== "" && emailAccount !== email && password === repassword
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Éditer mon compte</DialogTitle>
			<DialogContent>
				<TextField
					onChange={(event)=>{setEmail(event.target.value)}}
					value={email}
					autoFocus
					margin="dense"
					label="Adresse e-mail"
					type="email"
					fullWidth
					className="mb-3"
				/>
				<TextField
					onChange={(event)=>{setPassword(event.target.value)}}
					value={password}
					margin="dense"
					label="Mot de passe (laisser le champ vide pour ne pas le changer)"
					type="password"
					fullWidth
				/>
				<TextField
					onChange={(event)=>{setRepassword(event.target.value)}}
					value={repassword}
					margin="dense"
					label="Confirmation du mot de passe"
					type="password"
					fullWidth
				/>
				{sessionStorage.getItem("permission") !== "Composante" && (
					<ImageInputPreviewer
						handleChangeFile={setSignatureFile}
						handleChangeImage={setSignature}
						text={"Enregistrer une signature"}
						image={signature}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid()}>
					Modifier
				</Button>
			</DialogActions>
		</Dialog>
	);
}