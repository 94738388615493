import React, {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom'

import {Button} from "@material-ui/core";
import {MDBCol, MDBIcon, MDBRow, MDBSpinner} from "mdbreact";

import CompanyFormDialog from "../../components/dialog/CompanyFormDialog";

import StackdDataContext from "../../context/StackdDataContext";
import ToastContext from "../../context/ToastContext";
import stackd from "../../api/stackd.api";
import Alert from "../../utils/Alert";

export default function ViewCompany(){
    const { companyId } = useParams();
    const { state } = useLocation();

    const [open, setOpen] = useState(false)

    const [company, setCompany] = useState(state !== undefined ? state.company : null);
    const [contactDecision, setContactDecision] = useState(null)
    const [contactCertificate, setContactCertificate] = useState(null)

    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        if(company === null){
            stackd.get( `/companies/${companyId}`).then(response=>response.data).then(ta=>{setCompany(ta)})
            return;
        }

        let contactCertificatePromises = stackd.get(`/contacts/${company.contactIdCertificate}`)
        let contactDecisionPromises = stackd.get(`/contacts/${company.contactIdDecision}`)

        Promise.all([contactCertificatePromises, contactDecisionPromises]).then((result)=>{
            setContactCertificate(result[0].data)
            setContactDecision(result[1].data)
        });
    },[company, companyId])

    const editCompany = (companyChanged) => {
        stackd.patch(`/companies/${companyId}`, companyChanged).then(()=>{
            setCompany(companyChanged)
            toast.fire({title: "Modification validée", icon: "success"})
            setOpen(false)
        }).catch(Alert.error)
    }
    const editContact = (contactChanged, contactType) => {
        if(contactType === "contactCertificate")
            setContactCertificate(contactChanged)

        if(contactType === "contactDecision")
            setContactDecision(contactChanged)

        stackd.patch(`/contacts/${contactChanged.contactId}`, contactChanged).catch(Alert.error)
    }
    const deleteContact = (contactToDelete) => {
        stackd.delete(`/contacts/${contactToDelete.contactId}`).catch(Alert.error)
    }
    const createContact = (contactToCreate) => {
        setContactCertificate(contactToCreate)
        return stackd.post(`/contacts/`, contactToCreate).catch(Alert.error)
    }

    if(company === null || contactDecision === null)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    let contactDecisionPanelTitle = contactDecision.contactId === contactCertificate.contactId ? "Contact décision & attestation" : "Contact décision"

    return(
        <StackdDataContext.Provider value={{editCompany, createContact, editContact, deleteContact}}>
            <div id="view-ta" className="view-content">
                <MDBRow>
                    <MDBCol size="12">
                        <MainPanel company={company} handleOpenEdit={()=>{setOpen(true)}}/>
                        <ContactPanel title={contactDecisionPanelTitle} contact={contactDecision}/>
                        {contactDecision.contactId !== contactCertificate.contactId && (
                            <ContactPanel title="Contact attestation" contact={contactCertificate}/>
                        )}
                    </MDBCol>
                </MDBRow>
            </div>
            <CompanyFormDialog
                companyProp={company}
                contactDecisionProp={contactDecision}
                contactCertificateProp={contactCertificate}
                open={open}
                handleClose={()=>{setOpen(false)}}
            />
        </StackdDataContext.Provider>
    )
}

function MainPanel({company, handleOpenEdit}){
    return(
        <MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
            <MDBCol size="6">
                <h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{company.companyName}</h2>
                    <MDBRow>
                        <MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
                            <span className="mb-2">Adresse</span>
                            <span className="mb-2">Ville</span>
                            <span className="mb-2">Code postal</span>
                            <span className="mb-2">Siret</span>
                        </MDBCol>
                        <MDBCol size="6" style={{display: "inline-grid"}}>
                            <span className="mb-2">{company.address}</span>
                            <span className="mb-2">{company.city}</span>
                            <span className="mb-2">{company.postalCode}</span>
                            <span className="mb-2">{company.siretNum}</span>
                        </MDBCol>
                    </MDBRow>
            </MDBCol>
            <MDBCol size="6">
                <MDBRow className="justify-content-end">
                    <Button className="mr-4" color="primary" onClick={handleOpenEdit} disabled={false}>
                        <MDBIcon icon="pen-alt" className="mr-2"/>
                        Editer
                    </Button>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    )
}
function ContactPanel({title, contact}){
    return(
        <MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
            <MDBCol size="12">
                <h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{title}</h2>
                <MDBRow>
                    <MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
                        <span className="mb-2">Prénom Nom - Fonction</span>
                        <span className="mb-2">Adresse Mail</span>
                        <span className="mb-2">Numéro de téléphone</span>
                    </MDBCol>
                    <MDBCol size="6" style={{display: "inline-grid"}}>
                        <span className="mb-2">{contact.firstname} {contact.lastname} - {contact.position}</span>
                        <span className="mb-2">{contact.email}</span>
                        <span className="mb-2">{contact.phoneNumber}</span>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    )
}