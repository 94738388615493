import React, {useContext, useEffect, useState} from "react";

import {MDBCol, MDBRow} from "mdbreact";
import Button from "@material-ui/core/Button";
import {Alert} from '@material-ui/lab';

import {isCompanyExist, isSiretExist} from "../../api/gouv.api";
import stackd from "../../api/stackd.api";
import StackdDataContext from "../../context/StackdDataContext";


export default function SiretStep({handleChangeFormState, handleNextStep}){
    const [companyIdentifier, setCompanyIdentifier] = useState("")
    const [isFormatValid, setIsFormatValid] = useState(false)
    const [error, setError] = useState("")
    const {organisation} = useContext(StackdDataContext)

    useEffect(()=>{
        setIsFormatValid(companyIdentifier !== "")
    },[companyIdentifier])

    const handleCheckCompanyIdentifier = async () => {
        let companyData = {};
        setError("")

        try{
            if(companyIdentifier.length === 14 && companyIdentifier.match(/^[0-9]+$/)){
                const siret = companyIdentifier.replace(/\s/g, '');
                companyData = await isSiretExist(siret);
            } else {
                companyData = await isCompanyExist(companyIdentifier);
            }

            setError("")
            handleChangeFormState("company")(companyData)

            if("contactIdDecision" in companyData){
                const {data: contactDecision} = await stackd.get(`/contacts/${companyData.contactIdDecision}`)
                handleChangeFormState("contactDecision")(contactDecision)
            }
            if("contactIdCertificate" in companyData){
                const {data: contactCertificate} = await stackd.get(`/contacts/${companyData.contactIdCertificate}`)
                handleChangeFormState("contactCertificate")(contactCertificate)
            }

            handleNextStep()
        } catch(errorCheck) {
            setError("Votre raison sociale ou numéro de SIRET est invalide")
        }
    }

    let marginTop = error !== "" ? "10vh" : "15vh";

    return(
        <MDBRow className="justify-content-center" style={{fontFamily: "Montserrat, sans-serif", marginTop: marginTop}}>
            <img src={organisation.urlLogo} alt={`${organisation.name}-logo`} style={{marginBottom: "2rem", width: "30vw", height: "auto"}}/>
            <h5 className="py-5 px-5 text-center" style={{lineHeight: "2rem"}}>
                Faites votre promesse de versement de la Taxe d’apprentissage à {organisation.config.textName} et recevez votre reçu libératoire une fois le versement validé
            </h5>
            <MDBCol md="6">
                {error !== "" && (
                    <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
                        <Alert variant="outlined" severity="error">
                            {error}
                        </Alert>
                    </div>
                )}
                <label htmlFor="SIRET" className="black-text">
                    <b>Raison sociale ou numéro de SIRET</b>
                </label>

                <input
                    value={companyIdentifier}
                    style={{fontWeight: 500}}
                    type="text"
                    className="form-control"
                    id="input-siret"
                    onChange={(event)=>{setCompanyIdentifier(event.target.value)}}
                />

                <br/>
                <div className="text-center">
                    <Button
                        id="siret-form-button"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleCheckCompanyIdentifier}
                        disabled={!isFormatValid}
                    >
                        Valider
                    </Button>
                </div>
            </MDBCol>
        </MDBRow>
    )
}