import React, {useState} from "react";
import {MDBAnimation, MDBCol, MDBContainer, MDBRow} from "mdbreact";

import SiretStep from "../step/SiretStep";

import Alert from '../../utils/Alert'

import CompanyStep from "../step/CompanyStep";
import TAInfoStep from "../step/TAInfoStep";
import stackd from "../../api/stackd.api";
import PaymentInfoStep from "../step/PaymentInfoStep";

export default function PromiseTAForm(){
    const [activeStep, setActiveStep] = useState("siret")

    const [company, setCompany] = useState({
        companyId: "",
        companyName: "",
        address: "",
        postalCode: "",
        city: "",
        siretNum: "",
    })
    const [contactDecision, setContactDecision] = useState({
        contactId: "",
        lastname: "",
        firstname: "",
        position: "",
        email: "",
        phoneNumber: ""
    })
    const [contactCertificate, setContactCertificate] = useState({
        contactId: "",
        lastname: "",
        firstname: "",
        position: "",
        email: "",
        phoneNumber: ""
    })
    const [taInfo, setTaInfo] = useState( {
        amount: "",
        paymentType: "Virement bancaire",
        formationId: ""
    })

    const handleSubmission = async (taInfoProp) => {
        try{
            let companyId = ""

            if(company.companyId !== ""){
                companyId = company.companyId;
                await stackd.patch(`/companies/${company.companyId}`, {
                    companyName: company.companyName,
                    address: company.address,
                    postalCode: company.postalCode,
                    city: company.city,
                    siretNum: company.siretNum,
                    contactIdCertificate: contactCertificate.contactId,
                    contactIdDecision: contactDecision.contactId
                })
                await stackd.patch(`contacts/${contactDecision.contactId}`,contactDecision)
                await stackd.patch(`contacts/${contactCertificate.contactId}`,contactCertificate)
            } else {
                const {contactId: contactDecisionCreated} = await stackd.post(`contacts/${contactDecision.contactId}`,contactDecision).then(response=>response.data)
                const {contactId: contactCertificateCreated} = await stackd.post(`contacts/${contactCertificate.contactId}`,contactCertificate).then(response=>response.data)
                const {companyId: companyCreatedId} = await stackd.post(`/companies/${company.companyId}`, {
                    companyName: company.companyName,
                    address: company.address,
                    postalCode: company.postalCode,
                    city: company.city,
                    siretNum: company.siretNum,
                    contactIdCertificate: contactDecisionCreated,
                    contactIdDecision: contactCertificateCreated
                }).then(response=>response.data)
                companyId = companyCreatedId
            }
            taInfoProp["companyId"] = companyId;
            setTaInfo(taInfoProp)
            await stackd.post('/ta', taInfoProp).then(()=>{
                setActiveStep("paymentInfo")
            })
        }
        catch(error){
            Alert.error(error);
        }
    }

    const handleChangeFormState = (groupName) => (value) => {
        if(groupName === "company"){
            setCompany(value)
            return
        }

        if(groupName === "contactDecision"){
            setContactDecision(value)
            return
        }

        if(groupName === "contactCertificate"){
            setContactCertificate(value)
        }
    }

    return(
        <MDBContainer>
            {activeStep === "siret" ? (
                <SiretStep
                    handleChangeFormState={handleChangeFormState}
                    handleNextStep={()=>{setActiveStep("company")}}
                />
            ) : activeStep === "company" ? (
                <CompanyStepForm
                    handleChangeFormState={handleChangeFormState}
                    companyProp={company}
                    contactDecisionProp={contactDecision}
                    contactCertificateProp={contactCertificate}
                    handleNextStep={()=>{setActiveStep("taInfo")}}
                />
            ) : activeStep === "taInfo" ? (
               <TAStepForm
                   taInfo={taInfo}
                   handleChangeFormState={handleChangeFormState}
                   handleSubmission={handleSubmission}/>
            ) : activeStep === "paymentInfo" ? (
                <PaymentInfoStep
                    taInfo={taInfo}
                />
            ) : ""}
        </MDBContainer>
    );
}

function CompanyStepForm({handleChangeFormState, companyProp, contactDecisionProp, contactCertificateProp, handleNextStep}){
    return(
        <MDBRow className="justify-content-center">
            <MDBCol md="9" style={{overflow: "auto"}}>
                <MDBAnimation type="fadeIn">
                    <CompanyStep
                        handleChangeFormState={handleChangeFormState}
                        companyProp={companyProp}
                        contactDecisionProp={contactDecisionProp}
                        contactCertificateProp={contactCertificateProp}
                        handleNextStep={handleNextStep}
                        next="taInfo"
                    />
                </MDBAnimation>
            </MDBCol>
        </MDBRow>
    )
}

function TAStepForm({taInfo, handleChangeFormState, handleSubmission}){
    return(
        <MDBRow className="justify-content-center">
            <MDBCol md="9" style={{overflow: "auto"}}>
                <MDBAnimation type="fadeIn">
                    <TAInfoStep
                        handleChangeFormState={handleChangeFormState}
                        taInfo={taInfo}
                        handleSubmission={handleSubmission}
                    />
                </MDBAnimation>
            </MDBCol>
        </MDBRow>
    )
}
