import {
	Avatar,
	Card,
	CardContent,
	Grid,
	Typography
} from '@material-ui/core';
import {MDBIcon} from "mdbreact";

const StatsCard = ({title, value, icon, color}) => (
	<Card
		sx={{ height: '100%' }}
	>
		<CardContent>
			<Grid
				container
				spacing={3}
				sx={{ justifyContent: 'space-between' }}
			>
				<Grid item>
					<Typography
						color="textSecondary"
						gutterBottom
						variant="h6"
						style={{fontSize: "1rem"}}
					>
						{title}
					</Typography>
					<Typography
						color="textPrimary"
						variant="h4"
					>
						{value}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar style={{backgroundColor: color, height: "3vw", width: "3vw"}}>
						<MDBIcon icon={icon} fal={true}/>
					</Avatar>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
)

export default StatsCard