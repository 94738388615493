import React from 'react'

import {MDBCol, MDBRow} from "mdbreact";
import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';

export default function ImageInputPreviewer({handleChangeImage, text, image}){
	const handleChange = (event) => {
		const reader = new FileReader();
		reader.onload = function(e) {
			handleChangeImage(e.target.result);
		}
		reader.readAsDataURL(event.target.files[0])
	}

	return(
		<MDBRow>
			<MDBCol size="6">
				<Button
					style={{marginTop: "2rem"}}
					color="primary"
					startIcon={<ImageIcon />}
					component="label"
				>
					{text}
					<input
						onChange={handleChange}
						accept="image/png, image/jpeg"
						type="file"
						hidden
					/>
				</Button>
			</MDBCol>
			<MDBCol size="6">
				<img src={image} alt={`preview-${text}`} style={{width: "8vw"}}/>
			</MDBCol>
		</MDBRow>
	)
}