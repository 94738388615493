import React, {useContext} from "react";

import {useTheme} from "@material-ui/core/styles";

import {MDBAnimation, MDBCol, MDBRow, MDBSpinner} from "mdbreact";

import stackd from "../../api/stackd.api";
import storage from "../../api/storage.api";

import {NumberToLetter} from "convertir-nombre-lettre";
import StackdDataContext from "../../context/StackdDataContext";
import {useQuery} from "react-query";
import ErrorBox from "../ErrorBox";

export default function PaymentInfoStep({taInfo}){
	const {organisation} = useContext(StackdDataContext);
	const theme = useTheme();

	const {isLoading, error, data: contact} = useQuery(
		["get-contact", organisation.contactId],
		() => stackd.get(`/contacts/${organisation.contactId}`).then(response=>response.data)
	)

	if(isLoading)
		return <MDBSpinner/>

	if(error)
		return <ErrorBox error={error}/>

	let amountLetters = taInfo.amount.toString()
	if(amountLetters.includes(","))
		amountLetters = amountLetters.split(",");
	else
		amountLetters = [amountLetters, "00"]


	amountLetters = `${NumberToLetter(amountLetters[0])} euros et ${NumberToLetter(amountLetters[1])} centimes`

	return(
		<div style={{marginTop: "15vh"}}>
			<MDBRow className="justify-content-center">
				<MDBCol md="11" style={{overflow: "auto"}}>
					<MDBAnimation type="fadeIn">
						<h2>Votre promesse de versement a bien été enregistrée</h2>
						<p style={{fontSize: "1.2em", fontWeight: 500}} dangerouslySetInnerHTML={{__html: organisation.config.promiseSaved}}>
						</p>

						{taInfo.paymentType === "Virement bancaire" && (
							<div className="mt-2">
								<p style={{fontSize: "1rem", fontWeight: 400}}>
									Vous devez effectuer un virement bancaire d'une valeur de {taInfo.amount}€ à l'organisation
								</p>
								<img src={`${storage}${organisation.uriRIB}`} className="mt-3" alt={`rib-${organisation.name}`}/>
							</div>
						)}

						{taInfo.paymentType === "Chèque" && (
							<>
								<p style={{fontSize: "1rem", fontWeight: 400}}>
									Vous devez envoyer un chèque à l'organisation d'un montant de {taInfo.amount}€
								</p>
								<MDBRow className="py-4" style={{border: `2px solid ${theme.palette.primary.main}`}}>
									<MDBCol size="4" style={{fontWeight: "bold", display: "grid"}}>
										<span style={{marginBottom: "1rem"}}>Montant :</span>
										<span style={{marginBottom: "1rem"}}>Montant en toutes lettres :</span>
										<span style={{marginBottom: "1rem"}}>Ordre :</span>
										<hr/>
										<span style={{marginBottom: "1rem"}}>Nom de l'organisation :</span>
										<span style={{marginBottom: "1rem"}}>Adresse :</span>
										<span>Contact :</span>
									</MDBCol>
									<MDBCol size="8" style={{fontWeight: "500", display: "grid"}}>
										<span>{taInfo.amount} €</span>
										<span>{amountLetters}</span>
										<span>{organisation.chequeInfo}</span>
										<hr/>
										<span>{organisation.name}</span>
										<span>{organisation.address} - {organisation.postalCode} {organisation.city}</span>
										<span>{contact.firstname} {contact.lastname} - {contact.position}</span>
									</MDBCol>
								</MDBRow>
							</>
						)}
					</MDBAnimation>
				</MDBCol>
			</MDBRow>
		</div>
	)
}