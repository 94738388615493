import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import AuthRoute from './AuthRoute';

import DashboardCompanies from '../section/dashboard/DashboardCompanies';
import DashboardTA from '../section/dashboard/DashboardTA';
import DashboardFormations from "../section/dashboard/DashboardFormations";

import ViewCompany from '../section/view/ViewCompany';
import ViewTA from "../section/view/ViewTA";

import DashboardDepartments from "../section/dashboard/DashboardDepartments";
import DashboardStats from "../section/dashboard/DashboardStats";

const RoutesApp = () => {
    return (
        <Switch>
            <Route path='/' exact component={()=>(<Redirect to="/dashboard/ta"/>)}/>

            <Route path='/dashboard/ta' exact component={DashboardTA}/>
            <Route path='/dashboard/companies/' exact component={DashboardCompanies}/>
            <Route path='/dashboard/formations/' exact component={DashboardFormations}/>
            <Route path='/dashboard/departments/' exact component={DashboardDepartments}/>
            <Route path='/dashboard/stats/' exact component={DashboardStats}/>

            <AuthRoute path='/company/view/:companyId' exact component={ViewCompany} permissionsList={["Administrateur", "Comptable", "Composante"]}/>
            <AuthRoute path='/ta/view/:taId' exact component={ViewTA} permissionsList={["Administrateur", "Comptable", "Composante"]}/>

            <Route>
                404
            </Route>
        </Switch>
    );
};

export default RoutesApp;
