import React from 'react'
import {Redirect, Route} from 'react-router-dom'

export default function AuthRouteLogin({component : Component, type, ...rest}) {
    return (
        <Route {...rest} render={(props) => (
            sessionStorage.getItem("permission")
              ? <Component {...props} />
              : <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
          )} />
    )
}