import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';

import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import StackdDataContext from "../../context/StackdDataContext";
import TextField from "@material-ui/core/TextField";
import {MDBContainer, MDBRow} from "mdbreact";

moment.locale("fr");

export default function TAValidationDialog({isCheque, open, handleClose}) {
	const [dateTimePayment, setDateTimePayment] = useState(Date.now())
	const [chequeNum, setChequeNum] = useState("")

	const [isValid, setIsValid] = useState(false)

	const {handleValidTA} = useContext(StackdDataContext)

	useEffect(()=>{
		setIsValid(!isCheque ||(isCheque && chequeNum !== ""))
	}, [dateTimePayment, chequeNum, isCheque])

	return (
		<Dialog
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Valider une promesse de versement</DialogTitle>
			<DialogContent className="pt-0">
				<MDBContainer>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<KeyboardDatePicker
							margin="normal"
							id="dateTimePayment-picker-dialog"
							label="Date de reception du paiement"
							format="DD/MM/YYYY"
							value={dateTimePayment}
							onChange={(date) => {setDateTimePayment(date.unix()*1000)}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						/>
					</MuiPickersUtilsProvider>
					{isCheque && (
						<MDBRow className="ml-0 mr-0">
							<TextField
								onChange={event=>{setChequeNum(event.target.value)}}
								value={chequeNum}
								autoFocus
								margin="dense"
								label="Numéro du chèque"
								type="text"
								className="mb-3"
							/>
						</MDBRow>
					)}
				</MDBContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={()=>{handleClose(); handleValidTA(dateTimePayment, chequeNum)}} color="primary" disabled={!isValid}>
					Valider
				</Button>
			</DialogActions>
		</Dialog>
	);
}