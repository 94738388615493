import React, {useContext, useEffect, useState} from 'react'
import {MDBIcon} from 'mdbreact'

import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useParams} from "react-router-dom";
import stackd from "../../api/stackd.api";
import CalculatorFormDialog from "../dialog/CalculatorFormDialog";
import StackdDataContext from "../../context/StackdDataContext";
import {useQuery} from "react-query";

export default function TAInfoStep({taInfo, handleSubmission}){
    const {organisationId} = useParams()
    const {organisation} = useContext(StackdDataContext);

    const {data: formationId} = useQuery(
        ["get-formations-public", organisationId],
        () => stackd.get(`/formations/public/${organisationId}`).then(response=>response.data).then(formationList=>formationList[0].formationId)
    )

    const [paymentType, setPaymentType] = useState(taInfo.paymentType)
    const [amount, setAmount] = useState(taInfo.amount)

    const [openCalculator, setOpenCalculator] = useState(false)

    const [isValid, setIsValid] = useState(false)
    const [isPending, setIsPending] = useState(false)

    useEffect(()=>{
        setIsValid(amount !== "" && amount !== 0)
    }, [amount])

    return (
        <div style={{marginTop: "10rem"}} className="ta-info-step">
            <h3 className="font-weight-bold pl-0 my-4">
                <strong>Promesse de versement de votre taxe d’apprentissage</strong>
            </h3>

            <Button
                color="primary"
                onClick={()=>{setOpenCalculator(true)}}
                size={"large"}
            >
                Simuler le montant de la taxe d’apprentissage versable à {organisation.config.textName}
            </Button>

            <label htmlFor="AMOUNT" className="mt-4" style={{fontWeight: 500}}>
                <b>Montant que vous souhaitez verser à {organisation.config.textName} (en €)</b>
            </label>
            <input
                value={amount}
                style={{fontWeight: 500}}
                type="number"
                className="form-control"
                id="input-amount"
                onChange={(event)=>{setAmount(event.target.value)}}
            />

            <div className="mt-5">
                <FormLabel style={{fontWeight: 500, color: "#0F3F62"}} component="legend">
                    <b>Moyen de paiement</b>
                </FormLabel>
                <RadioGroup
                    row
                    aria-label="paymentType"
                    name="paymentType"
                    value={paymentType}
                    onChange={(event)=>{setPaymentType(event.target.value)}}
                >
                    <FormControlLabel value={"Virement bancaire"} control={<Radio id={"input-check-virement"}/>} label="Virement bancaire" />
                    <FormControlLabel value={"Chèque"} control={<Radio id={"input-check-cheque"}/>} label="Chèque" />
                </RadioGroup>
            </div>

            <CalculatorFormDialog
                open={openCalculator}
                handleClose={()=>{setOpenCalculator(false)}}
                handleCalc={(amountInput)=>{setAmount(amountInput)}}
            />

            <div className="text-center mt-5">
                <Button
                    id="taInfo-form-button"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={()=>{setIsPending(true); handleSubmission({
                        amount: amount,
                        paymentType: paymentType,
                        formationId: formationId
                    })}}
                    disabled={!isValid}
                >
                    Terminé
                    {isPending ? (<MDBIcon icon="spinner" className="ml-1" pulse fixed />) : ""}
                </Button>
            </div>
        </div>
     )
}