import React, {useEffect, useState} from "react";
import stackd from "../../api/stackd.api";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import {PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip} from 'recharts';
import StatsCard from "../../components/StatsCard";

export default function DashboardStats() {
	const [nbCompanies, setNbCompanies] = useState(0)
	const [nbCompaniesValidate, setNbCompaniesValidate] = useState(0)
	const [nbCompaniesPromise, setNbCompaniesPromise] = useState(0)

	const [totalAmount, setTotalAmount] = useState(0)
	const [totalAmountValidate, setTotalAmountValidate] = useState(0)
	const [totalAmountPromise, setTotalAmountPromise] = useState(0)

	const [averageAmount, setAverageAmount] = useState(0)

	useEffect(() => {
		Promise.all([
			stackd.get("/companies"),
			stackd.get("/ta")
		]).then((result) => {
			const companiesList = result[0].data;
			const taList = result[1].data;

			const taValidateList = taList.filter(ta => ta.state === "Versée")
			const taPromiseList = taList.filter(ta => ta.state === "Non-versée")

			setNbCompanies(companiesList.length)
			setNbCompaniesValidate(taValidateList.map(ta => ta.companyId).length)
			setNbCompaniesPromise(taPromiseList.map(ta => ta.companyId).length)

			setTotalAmount(round(taList.map(getAmount).reduce((total, amount) => total + amount, 0)))
			setTotalAmountValidate(round(taValidateList.map(getAmount).reduce((total, amount) => total + amount, 0)))
			setTotalAmountPromise(round(taPromiseList.map(getAmount).reduce((total, amount) => total + amount, 0)))

			setAverageAmount(round(totalAmount / companiesList.length))
		})
	}, [totalAmount])

	const round = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100
	}
	const getAmount = (ta) => parseFloat(ta.amount.replace(",", "."))

	const style = {
		marginTop: "1rem",
		top: '65%',
		right: 0,
		transform: 'translate(0, -50%)',
		lineHeight: '24px',
	};

	const companyPieChartData = [
		{name: "Nb d'entreprise ayant versée sa taxe", value: nbCompaniesValidate},
		{name: "Nb d'entreprise ayant fait une promesse de versement", value: nbCompaniesPromise},
		{
			name: "Nb d'entreprise n'ayant pas fait de promesse",
			value: nbCompanies - (nbCompaniesValidate + nbCompaniesPromise)
		}
	]

	const amountPieChartData = [
		{name: "Montant ayant été versée en €", value: totalAmountValidate, label: `${totalAmountValidate} €`},
		{name: "Montant ayant été promis en €", value: totalAmountPromise, label: `${totalAmountPromise} €`},
	]

	return (
		<div style={{paddingTop: "8vh"}} id="dashboard-stats">
			<MDBContainer>
				<MDBRow center={true}>
					<MDBCol size="5">
						<h3 className="text-center">Entreprises</h3>
						<div style={{width: '100%', height: '90%'}}>
							<ResponsiveContainer width="100%" height="100%">
								<PieChart height={345} width={450}>
									<Pie
										data={companyPieChartData}
										dataKey="value"
										cx="50%"
										cy="30%"
										innerRadius={60}
										outerRadius={80}
										fill="#8884d8"
										label
									>
										<Cell key={`cell-validate`} fill={"#87c882"}/>
										<Cell key={`cell-not-validate`} fill={"#ee8c64"}/>
										<Cell key={`cell-not-register`} fill={"#a5a5a5"}/>
									</Pie>
									<Tooltip/>
									<Legend
										iconSize={10}
										layout="horizontal"
										verticalAlign="middle"
										wrapperStyle={style}
									/>
								</PieChart>
							</ResponsiveContainer>
						</div>
					</MDBCol>
					<MDBCol size="5">
						<h3 className="text-center">Montants</h3>
						<div style={{width: '100%', height: '90%'}}>
							<ResponsiveContainer>
								<PieChart>
									<Pie
										label={(entry) => `${entry.value.toLocaleString("fr-FR")}€`}
										data={amountPieChartData}
										dataKey="value"
										cx="50%"
										cy="30%"
										innerRadius={60}
										outerRadius={80}
										fill="#8884d8"
									>
										<Cell key={`cell-validate`} fill={"#87c882"}/>
										<Cell key={`cell-not-validate`} fill={"#ee8c64"}/>
										<Cell key={`cell-not-register`} fill={"#a5a5a5"}/>
									</Pie>
									<Tooltip/>
									<Legend
										iconSize={10}
										layout="horizontal"
										verticalAlign="middle"
										wrapperStyle={style}
									/>
								</PieChart>
							</ResponsiveContainer>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow center={true}>
					<MDBCol size="4">
						<StatsCard
							title={"Montant (promis + collecté)"}
							value={`${totalAmount.toLocaleString('fr-FR')} €`}
							icon={"euro-sign"}
							color={"#0F3F62"}
						/>
					</MDBCol>
					<MDBCol size="4">
						<StatsCard
							title={"Montant moyen collecté"}
							value={`${averageAmount.toLocaleString('fr-FR')} €`}
							icon={"calculator"}
							color={"#0F3F62"}
						/>
					</MDBCol>
					<MDBCol size="4">
						<StatsCard
							title={"Nombre d'entreprise total"}
							value={nbCompanies}
							icon={"briefcase"}
							color={"#0F3F62"}
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	)
}
