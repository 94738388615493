import React, {useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBIcon, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Button} from "@material-ui/core";

import stackd from '../../api/stackd.api';

import Alert from "../../utils/Alert";
import ListUtils from "../../utils/ListUtils";
import StackdDataContext from "../../context/StackdDataContext";

import '../../assets/css/dashboard.css'
import DepartmentRow from "../../components/row/DepartmentRow";
import DepartmentFormDialog from "../../components/dialog/DepartmentFormDialog";
import FilterSearch from "../../components/FilterSearch";

export default function DashboardDepartments(){
    const [isLoading, setIsLoading] = useState(true);
    const [filterSearch, setFilterSearch] = useState("")

    const [departmentsList, setDepartmentsList] = useState([])
    const [departmentsListDisplay, setDepartmentsListDisplay] = useState([])

    const [formationsList, setFormationsList] = useState([])
    const [taList, setTaList] = useState([])

    const [AddingDepartmentDialogFormOpen, setAddingDepartmentDialogFormOpen] = useState(false)

    const addDepartment = (department) => {
        setDepartmentsList([...departmentsList, department])
    }

    const addFormation = (formation) => {
        setFormationsList([...formationsList, formation])
    }

    const editDepartment = (department) => {
        setDepartmentsList(departmentsList.map(departmentElement => departmentElement.departmentId === department.departmentId ? department : departmentElement))
    }

    const deleteDepartment = (department) => {
        setDepartmentsList(departmentsList.filter((departmentElement)=>departmentElement.departmentId!==department.departmentId))
    }

    useEffect(()=>{
        const promisesList = [
            stackd.get('/ta'),
            stackd.get('/formations'),
            stackd.get('/departments')
        ]
        Promise.all(promisesList).then((results)=>{
            setTaList(results[0].data)
            setFormationsList(results[1].data)
            setDepartmentsList(results[2].data)
            setIsLoading(false)
        }).catch(Alert.error)
    }, [])
    useEffect(()=>{
        let list = departmentsList
        if(filterSearch !== "")
            list = ListUtils.filterSearchContain(departmentsList, "departmentName", filterSearch);

        setDepartmentsListDisplay(list)
    }, [filterSearch, departmentsList])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <StackdDataContext.Provider value={{addFormation, deleteDepartment, editDepartment, addDepartment}}>
            <div className="dashboard" id="dashboard-department">
                <div className="dashboard-header mb-3">
                    <MDBRow>
                        <MDBCol size="4" className="pl-0">
                            <MDBRow>
                                <MDBCol size="12">
                                    <FilterSearch
                                        placeholder={"Recherchez une structure"}
                                        handle={setFilterSearch}
                                        value={filterSearch}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="4" className="justify-content-center">
                            <Button
                                id="button-add-department"
                                color="primary"
                                size="large"
                                disabled={sessionStorage.getItem("permission") !== "Comptable"}
                                onClick={()=>{setAddingDepartmentDialogFormOpen(true)}}
                                style={{backgroundColor: "#5469d4", color: "white", marginTop: "1.5rem"}}
                            >
                                <MDBIcon icon="plus" className="mr-2"/>
                                Ajouter une structure
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </div>
                <div className="dashboard-content card">
                    <MDBAnimation type="fadeIn">
                        <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
                            <MDBTableHead color="primary">
                                <tr>
                                    <th style={{paddingLeft: "2rem"}}>Nom de la structure</th>
                                    <th>Nombre de taxe récoltée</th>
                                    <th>Montant total récolté</th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody  >
                                {departmentsListDisplay.map((department, index)=>(
                                    <DepartmentRow
                                        taList={taList}
                                        formationsList={formationsList}
                                        department={department}
                                        key={index}
                                    />
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBAnimation>
                </div>
            </div>
            <DepartmentFormDialog
                open={AddingDepartmentDialogFormOpen}
                editMode={false}
                handleClose={()=>{setAddingDepartmentDialogFormOpen(false)}}
            />
        </StackdDataContext.Provider>
    )
}