import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {MDBIcon, MDBSpinner} from "mdbreact";
import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {AccountCircle} from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import RoutesApp from "../routes/RoutesApp";
import AccountFormDialog from "../components/dialog/AccountFormDialog";
import SideBarLink from "../components/SideBarLink";

import logo from '../assets/img/stackd-logo-title.png'
import stackd from "../api/stackd.api";

const routes_config = require('../routes/routes.config.json');

export default function RoutesWithNavigationApp(){
    const drawerWidth = 250;

    const theme = useTheme();
    let history = useHistory();
    let location = useLocation();

    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogAccountOpen, setIsDialogAccountOpen] = useState(false)

    const [config, setConfig] = useState(localStorage.getItem("config") !== null ? JSON.parse(localStorage.getItem("config")) : null)
    const [organisationId, setOrganisationId] = useState(sessionStorage.getItem("permission") === "Comptable" ? sessionStorage.getItem("resId") : null)

    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            flexGrow: 1
        },
        appBar: {
            backgroundColor: "#ffffff",
            color: "#444448",
            right: "inherit",
            [theme.breakpoints.up('sm')]: {
                zIndex: 1050,
                boxShadow: "none",
                paddingLeft: `calc(${drawerWidth}px)`,
            },
            [theme.breakpoints.down('md')]: {
                zIndex: "inherit",
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                backgroundColor: "#444448",
                color: "white",
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // toolbar
        toolbar:{backgroundColor: theme.palette.primary.light},
        toolbarTitle:{
            flexGrow: "1",
            color: theme.palette.primary.main,
            [theme.breakpoints.up('md')]: {
                marginTop: "1rem",
                fontSize: "1.4rem",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.2rem"
            },
        },
        // drawer
        drawer: {
            backgroundColor: "#90CCF4",
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerHeader: {
            backgroundColor: "white",
            marginTop: "2rem",
            marginBottom: "1.5rem",
            alignItems: "center"
        },
        drawerTitle:{
            color: theme.palette.primary.main,
            marginLeft: "1rem",
            fontWeight: "400",
            marginBottom: "initial"
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#ffffff",
        },
        drawerImg: {
            [theme.breakpoints.up('md')]: {
                width: "60%",
            },
            [theme.breakpoints.down('md')]: {
                width: "35vw",
            },
        },
        // drawer content
        listItemIcon:{
            minWidth: "45px"
        },
        logoutLink:{
            position: "relative",
            fontSize: "1.2rem",
        },
        content: {
            flexGrow: 1,
            padding: "1.5rem",
            margin : "1.2rem",
            marginBottom: 0
        },
    }));
    const classes = useStyles();

    const open = Boolean(anchorEl)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        localStorage.clear()
        sessionStorage.clear();
        return history.push('/login')
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePromisePage = () => {
        history.push(`/verser-ma-taxe-apprentissage/${organisationId}`)
    }

    useEffect(()=>{
        async function fetch() {
            let localOrganisationId = "";
            if (sessionStorage.getItem("permission") === "Comptable")
                localOrganisationId = sessionStorage.getItem("resId")

            if (sessionStorage.getItem("permission") === "Composante") {
                const response = await stackd.get(`/departments/${sessionStorage.getItem("resId")}`)
                localOrganisationId = response.data.organisationId
            }

            const responseOrganisation = await stackd.get(`/organisations/${localOrganisationId}`);
            localStorage.setItem("config", JSON.stringify(responseOrganisation.data.config));
            setConfig(responseOrganisation.data.config)
            setOrganisationId(localOrganisationId)
        }
        fetch()
    }, [])

    if(config === null)
        return <MDBSpinner/>

    const drawer = (
        <div style={{textAlign: "center"}} className="drawer">
            <div className={classes.drawerHeader}>
                <img src={logo} alt="logo-stackd" className={classes.drawerImg}/>
            </div>
            <List style={{color: "#898CAA"}}>
                {routes_config.filter(route=>!config.disabledSection.includes(route.to)).map((route, index)=>(
                    <SideBarLink
                        key={index}
                        to={route.to}
                        permissionsListAllowed={route.permissionsListAllowed}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <MDBIcon icon={route.icon} light={true} size="2x" className="mr-4"/>
                        </ListItemIcon>
                        <ListItemText primary={route.text} style={{fontSize: "1.4rem"}} disableTypography={true}/>
                    </SideBarLink>
                ))}
            </List>
            <div className="fixed-bottom pb-4 ml-3 text-left">
                <button style={{backgroundColor: "transparent", border: "none", textDecoration: "underline", fontSize: "1.2rem"}} className="text-underline mt-3" onClick={handlePromisePage} >
                    <MDBIcon far icon="home float-left  pr-3 mt-1 font-weight-lighter" />
                    Page de versement
                </button>
                <br/>
                <button id="button-logout" style={{backgroundColor: "transparent", border: "none", textDecoration: "underline", fontSize: "1.2rem"}} className="text-underline mt-3" onClick={handleLogout}>
                    <MDBIcon far icon="sign-in-alt float-left  pr-3 mt-1 font-weight-lighter" />
                    Déconnexion
                </button>
            </div>
        </div>
    );

    const container = window.document.body

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar style={{paddingTop: "1rem"}} className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    {routes_config.find(route=>route.to === location.pathname) &&
                        <Typography variant="h4" className={classes.toolbarTitle} noWrap>
                            {routes_config.find(route=>route.to === location.pathname).title}
                        </Typography>
                    }

                    {location.pathname.includes("view") &&
                        <div style={{flexGrow: 1}}>
                            <Button className="ml-3" size="large" onClick={()=>{history.goBack()}} color="primary">
                                <ArrowBackIcon className="mr-2"/>
                                <span className="pr-2">Retour</span>
                            </Button>
                        </div>
                    }

                    <Button
                        variant="contained"
                        color="secondary"
                        style={{backgroundColor: "transparent", color: "black"}}
                        startIcon={<AccountCircle fontSize={"large"} />}
                        onClick={handleMenu}
                    >
                        {sessionStorage.getItem("permission")}
                    </Button>
                    <TopBarAccountMenu
                        handleClose={handleClose}
                        anchorEl={anchorEl}
                        open={open}
                        handleOpenDialog={()=>{handleClose(); setIsDialogAccountOpen(true)}}
                    />

                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                        ModalProps={{ keepMounted: true, }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{ paper: classes.drawerPaper, }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <RoutesApp/>
                <AccountFormDialog
                    open={isDialogAccountOpen}
                    handleClose={()=>{setIsDialogAccountOpen(false)}}
                />
            </main>
        </div>
    )
}

const TopBarAccountMenu = ({anchorEl, handleClose, open, handleOpenDialog}) => {
    return(
        <Menu
            getContentAnchorEl={null}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={handleOpenDialog}>Modifier mon profil</MenuItem>
        </Menu>
    )

}