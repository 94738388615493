import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import RoutesWithNavigationApp from "./routes-navigation/RoutesWithNavigationApp";
import AuthRouteLogin from './routes/AuthRouteLogin';
import SignInPage from "./pages/SignInPage";
import PromiseTAPage from "./pages/PromiseTAPage";

import stackd from "./api/stackd.api";

import './assets/css/app.css';

export default function App() {
    const [isLoading, setIsLoading] = useState(localStorage.getItem("jwt_token") !== null && sessionStorage.getItem("accountId") === null)
    const {pathname} = useLocation();

    useEffect(()=>{
        if(localStorage.getItem("jwt_token") !== null && sessionStorage.getItem("accountId") !== null){
            return;
        }

        stackd.get('/auth')
            .then(response=>response.data)
            .then(async (authData) => {
                const responseAccount = await stackd.get(`/accounts/${authData.accountId}`);
                const account = responseAccount.data;

                sessionStorage.setItem("accountId", authData.accountId);
                sessionStorage.setItem("permission", account.permission);
                sessionStorage.setItem("resId", account.resId);

                setIsLoading(false)
            })
    }, [])

    const notConnectedPathname = ["login", "verser-ma-taxe-apprentissage"]

    if(localStorage.getItem("jwt_token") === null && !notConnectedPathname.includes(pathname.split('/')[1]))
        return <Redirect to="/verser-ma-taxe-apprentissage/Univ-OaSmXDqVlPxUFgCMQQyE"/>

    if(isLoading)
        return <MDBSpinner/>

    if(pathname === "/login" && localStorage.getItem("jwt_token") !== null && sessionStorage.getItem("accountId") !== null)
        return <Redirect to="/dashboard/ta"/>

    return(
        <Switch>
            <Route path='/verser-ma-taxe-apprentissage/:organisationId' exact component={PromiseTAPage} />
            <Route path='/login' exact component={SignInPage} />
            <AuthRouteLogin path='/' component={RoutesWithNavigationApp} />
        </Switch>
    );
}
