import Swal from 'sweetalert2';

class Alert {
    static error(error){
        console.error(error)

        let options = {
            icon: "error",
            title: "Oups une erreur est survenue",
            showCancelButton: false
        }

        if(error.response && error.response.data.error){
            options = {...options, "text": error.response.data.error}
        } else if(error.response && error.response.data.sqlMessage) {
            options = {...options, "text": error.response.data.sqlMessage}
        } else if(typeof(error) === "string") {
            options = {...options, "text": error}
        }

        Swal.fire(options);
    }

    static warningDelete(){
        const swalOptions = {
            title: 'Êtes-vous sûr ?',
            text: 'Vous ne pourrez pas revenir en arrière par la suite',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: "Annuler"
        };

        return new Promise((resolve => {
            Swal.fire(swalOptions).then((result) => {
                if (result.value) {
                    resolve()
                }
            })
        }))
    }
}

export default Alert