import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	ListSubheader,
	MenuItem,
	Radio,
	RadioGroup,
	Select
} from "@material-ui/core";
import StackdDataContext from "../../context/StackdDataContext";
import stackd from "../../api/stackd.api";
import {MDBRow, MDBSpinner} from "mdbreact";

export default function TAFormDialog({open, data, handleClose}) {

	const [departmentsList, setDepartmentsList] = useState([])
	const [formationsList, setFormationsList] = useState([])

	const [amount, setAmount] = useState(data.amount)
	const [additionalInfo, setAdditionalInfo] = useState(data.additionalInfo)
	const [paymentType, setPaymentType] = useState(data.paymentType)
	const [formationId, setFormationId] = useState(data.formationId)

	const [isValid, setIsValid] = useState(false)

	const {handleEditTA} = useContext(StackdDataContext)

	useEffect(()=>{
		Promise.all([
			stackd.get("/departments/"),
			stackd.get("/formations/")
		]).then((result)=>{
			setDepartmentsList(result[0].data)
			setFormationsList(result[1].data)
		})
	}, [])

	useEffect(()=>{
		const notEmpty = amount !== "" && formationId !== null
		const hasBeenChanged = amount !== data.amount || formationId !== data.formationId || paymentType !== data.paymentType || additionalInfo !== data.additionalInfo

		setIsValid(notEmpty && hasBeenChanged)
	}, [amount, paymentType, formationId, additionalInfo, data])

	const handleSubmit = () => {
		handleEditTA({...data, ...{
			amount: amount,
			paymentType: paymentType,
			formationId: formationId,
			additionalInfo: additionalInfo
		}})
	}

	if(departmentsList === [] || formationsList === [])
		return <MDBSpinner/>

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Editer {data.state === "Versée" ? "un versement": "une promesse de versement"}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus={true}
					id="input-amount"
					onChange={(event)=>{setAmount(event.target.value)}}
					value={amount}
					disabled={data.state === "Versée"}
					margin="dense"
					label="Montant"
					type="text"
					fullWidth
					className="mb-3"
				/>
				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<FormControl component="fieldset">
						<FormLabel component="legend">Moyen de paiement</FormLabel>
						<RadioGroup aria-label="paymentType" name="paymentType" value={paymentType} onChange={(event) => {setPaymentType(event.target.value);}}>
							<FormControlLabel disabled={data.state === "Versée"} value="Chèque" control={<Radio />} label="Chèque" />
							<FormControlLabel disabled={data.state === "Versée"} value="Virement bancaire" control={<Radio />} label="Virement bancaire" />
						</RadioGroup>
					</FormControl>
				</MDBRow>
				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<FormControl id="assignment-select-div">
						<InputLabel id="assignment-select-label">Affecter à une formation ou une structure</InputLabel>
						<Select
							fullWidth={true}
							id="assignment-select"
							style={{minWidth: "18vw"}}
							value={formationId}
							onChange={(event)=>{setFormationId(event.target.value)}}
						>
							<ListSubheader>Structures</ListSubheader>
							{formationsList.filter(formationElement=>formationElement.formationName === "defaut").map((formation, index)=>{
								const department = departmentsList.find(departmentElement=>departmentElement.departmentId===formation.departmentId)
								return (
									<MenuItem value={formation.formationId} key={index}>
										{department.departmentName}
									</MenuItem>
								)
							})}
							<ListSubheader>Formations</ListSubheader>
							{formationsList.filter((formationElement)=>formationElement.formationName !== "defaut").map((formation, index)=>(
								<MenuItem value={formation.formationId} key={index}>
									{formation.formationName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBRow>
				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<TextField
						id="input-additional-info"
						onChange={(event)=>{setAdditionalInfo(event.target.value)}}
						value={additionalInfo}
						margin="dense"
						label="Informations complémentaires"
						type="text"
						fullWidth
						className="mb-3"
					/>
				</MDBRow>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid}>
					Modifier
				</Button>
			</DialogActions>
		</Dialog>
	);
}