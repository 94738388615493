import React, {useContext, useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import {MDBIcon} from 'mdbreact';
import DepartmentFormDialog from "../dialog/DepartmentFormDialog";


import stackd from '../../api/stackd.api';
import ToastContext from "../../context/ToastContext";
import StackdDataContext from "../../context/StackdDataContext";

import Alert from "../../utils/Alert";

export default function DepartmentRow({taList, formationsList, department}){
    const formationsIdList = formationsList.filter(formation=>formation.departmentId === department.departmentId).map(formation=>formation.formationId)
    const taListDepartment = taList.filter(ta=>formationsIdList.includes(ta.formationId))

    const sumTA = taListDepartment.reduce((sum,ta)=>sum+parseFloat(ta.amount), 0);

    return(
        <tr>
            <td>
                <div className="d-block" style={{maxWidth: "20vw"}}>
                    <Typography noWrap={true}>
                        {department.departmentName}
                    </Typography>
                </div>
            </td>
            <td>{taListDepartment.length}</td>
            <td>{sumTA} €</td>
            <ActionTd department={department} editDisabled={formationsIdList.length > 0}/>
        </tr>
    )
}

const ActionTd = ({department, editDisabled}) => {
    const [open, setOpen] = useState(false)
    const {toast} = useContext(ToastContext)

    const {deleteDepartment} = useContext(StackdDataContext)

    const handleDeleteFormation = () => {
        Alert.warningDelete().then(()=>{
            stackd.delete(`/departments/${department.departmentId}`)
                .then(()=>{
                        toast.fire({title: 'Structure supprimée!', icon: "success"})
                        deleteDepartment(department)
                    })
                .catch(Alert.error)
        })
    }

    return(
        <td style={{textAlign: "center"}} className="btn-action">
            <Button size="medium" className="mr-2" onClick={()=>{setOpen(true)}} color="primary">
                <MDBIcon icon="pen-alt" className="mr-2"/>
                Editer
            </Button>
            <Button color="secondary" size="medium" onClick={handleDeleteFormation}>
                <MDBIcon icon="trash-alt" className="mr-2"/>
                Supprimer
            </Button>
            <DepartmentFormDialog
                department={department}
                editMode={true}
                open={open}
                handleClose={()=>{setOpen(false)}}
            />
        </td>
    )
}