import axios from 'axios';

var stackd_config = require('./config/stackd.api.json');

console.log("build mode", process.env.REACT_APP_BUILD_MODE)
const stackdApiUrl = stackd_config[process.env.REACT_APP_BUILD_MODE];

var stackd = axios.create({baseURL: stackdApiUrl});

if(localStorage.getItem("jwt_token")!==null)
    stackd.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("jwt_token")}`;

const get_company_from_id = (companyId) => (
    stackd.get(`/companies/${companyId}`).then(response=>response.data)
)

const get_contact_from_id = (contactId) => (
    stackd.get(`/contacts/${contactId}`).then(response=>response.data)
)

const update_company = (companyId, company) => (
    stackd.patch(`/companies/${companyId}`, company)
)

const update_contact = (contactId, contact) => (
    stackd.patch(`/contacts/${contactId}`, contact)
)

const delete_contact = (contactId) => (
    stackd.delete(`/contacts/${contactId}`)
)

const create_contact = (contact) => (
    stackd.post(`/contacts/`, contact)
)

export default stackd
export {
    get_company_from_id,
    get_contact_from_id,
    update_company,
    update_contact,
    delete_contact,
    create_contact
}