import React from 'react'
import {Redirect, Route} from 'react-router-dom'

export default function AuthRoute({component : Component, permissionsList, ...rest}) {
    return (
        <Route {...rest} render={(props) => (
            permissionsList.includes(sessionStorage.getItem("permission"))
              ? <Component {...props} />
              : <Redirect to={{
                  pathname: '/',
                  state: { from: props.location }
                }} />
          )} />
    )
}