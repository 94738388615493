import React, {useContext, useEffect, useState} from 'react'
import {MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {useHistory} from "react-router-dom";
import {Button, useTheme} from "@material-ui/core";

import Alert from '../utils/Alert';
import ToastContext from "../context/ToastContext";
import stackd from '../api/stackd.api';

import stackdLogo from '../assets/img/stackd-logo-title.png'
import svgLogin from '../assets/img/svg/login-svg.svg'

export default function SignInPage(){
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isValidate, setIsValidate] = useState(false)

    const [isPending, setIsPending] = useState(false)

    let history = useHistory();
    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        setIsValidate(email!=="" && password!=="")
    }, [email, password])

    const handleSubmit = async (event) =>{
        event.preventDefault();

        try{
            const {data: authData} = await stackd.post('/auth', {email: email, password: password});

            localStorage.setItem("jwt_token", authData.accessToken);
            localStorage.setItem("refreshToken", authData.refreshToken);
            sessionStorage.setItem("accountId", authData.accountId);

            stackd.defaults.headers.common['Authorization'] = `Bearer ${authData.accessToken}`;

            const accountResponse = await stackd.get(`/accounts/${authData.accountId}`);

            console.log("react_response", accountResponse.data)

            sessionStorage.setItem("permission", accountResponse.data.permission);
            if(accountResponse.data.hasOwnProperty("resId"))
                sessionStorage.setItem("resId", accountResponse.data.resId);

            toast.fire({icon: 'success', title: 'Connecté'})
            history.push(`/dashboard/ta`)
        } catch(error){
            setIsPending(false)
            setPassword("")
            Alert.error(error);
        }
    }

    const theme = useTheme();

    return(
        <div>
            <h3 style={{color: theme.palette.primary.main, fontFamily: "Montserrat, sans-serif", marginTop: "5rem", marginLeft: "3rem", position: "absolute"}}>
                <img src={stackdLogo} alt="logo-stackd" className="ml-3" style={{width: "40%"}}/>
            </h3>
            <MDBRow style={{marginLeft: "0px", marginRight: "0px", height: "100vh", justifyContent: "center", backgroundColor: "white"}}>
                <MDBCol md="1"/>
                <MDBCol md="4" className="container" style={{alignItems: "center", display: "grid"}}>
                    <MDBRow>
                        <MDBCol size="10">
                            <form onSubmit={handleSubmit}>
                                <h2 style={{textAlign: "center"}}>Connexion</h2>
                                <br/>

                                <MDBInput
                                    focused
                                    size="lg"
                                    label="Adresse e-mail"
                                    getValue={(value)=>{setEmail(value)}}
                                    icon="envelope"
                                    value={email}
                                    iconLight={true}
                                    type="email"
                                    style={{fontSize: "1.2rem"}}
                                />

                                <MDBInput
                                    size="lg"
                                    value={password}
                                    getValue={(value)=>{setPassword(value)}}
                                    label="Mot de passe"
                                    icon="lock"
                                    iconLight={true}
                                    type="password"
                                    style={{fontSize: "1.2rem"}}
                                />

                                <div className="text-center" style={{marginTop: "2rem"}}>
                                    <Button variant="contained" type="submit" color="primary" disabled={!isValidate} className="mt-3" size="large">
                                        Connexion {isPending && <MDBIcon icon="spinner" pulse size="3x" fixed />}
                                    </Button>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{backgroundColor: theme.palette.primary.light, textAlign: "center", display: "flex"}}>
                    <img src={svgLogin} alt="login-svg" className="img-fluid" style={{margin: "auto"}}/>
                </MDBCol>
            </MDBRow>
        </div>
    );
}