import React, {useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBIcon, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Button} from "@material-ui/core";

import FormationRow from "../../components/row/FormationRow";
import FormationFormDialog from "../../components/dialog/FormationFormDialog";

import stackd from '../../api/stackd.api';

import Alert from "../../utils/Alert";
import ListUtils from "../../utils/ListUtils";
import StackdDataContext from "../../context/StackdDataContext";

import '../../assets/css/dashboard.css'
import FilterSearch from "../../components/FilterSearch";

export default function DashboardFormations(){
    const [isLoading, setIsLoading] = useState(true);
    const [filterSearch, setFilterSearch] = useState("")

    const [departmentsList, setDepartmentsList] = useState([])
    const [formationsList, setFormationsList] = useState([])
    const [formationsListDisplay, setFormationsListDisplay] = useState([])
    const [taList, setTaList] = useState([])

    const [AddingFormationDialogFormOpen, setAddingFormationDialogFormOpen] = useState(false)

    const addFormation = (formation) => {
        setFormationsList([...formationsList, formation])
    }

    const editFormation = (formation) => {
        setFormationsList(formationsList.map(formationElement => formationElement.formationId === formation.formationId ? formation : formationElement))
    }

    const deleteFormation = (formation) => {
        setFormationsList(formationsList.filter((formationElement)=>formationElement.formationId!==formation.formationId))
    }

    useEffect(()=>{
        const promisesList = [
            stackd.get('/ta'),
            stackd.get('/formations'),
            stackd.get('/departments')
        ]
        Promise.all(promisesList).then((results)=>{
            const {data: taListData} = results[0]
            const {data: formationsListData} = results[1]
            const {data: departmentsListData} = results[2]

            setTaList(taListData)
            setFormationsList(formationsListData.filter(formation=>formation.formationName !== "defaut"))
            setDepartmentsList(departmentsListData)
            setIsLoading(false)
        }).catch(Alert.error)
    }, [])

    useEffect(()=>{
        let list = formationsList
        if(filterSearch !== "")
            list = ListUtils.filterSearchContain(formationsList, "formationName", filterSearch);

        setFormationsListDisplay(list)
    }, [filterSearch, formationsList])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <StackdDataContext.Provider value={{addFormation, deleteFormation, editFormation, departmentsList}}>
            <div className="dashboard" id="dashboard-formation">
                <div className="dashboard-header mb-3">
                    <MDBRow>
                        <MDBCol size="4" className="pl-0">
                            <MDBRow>
                                <MDBCol size="12">
                                    <FilterSearch
                                        placeholder="Rechercher une formations"
                                        value={filterSearch}
                                        handle={setFilterSearch}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="4" className="justify-content-center">
                            <Button
                                color="primary"
                                size="large"
                                onClick={()=>{setAddingFormationDialogFormOpen(true)}}
                                style={{backgroundColor: "#5469d4", color: "white", marginTop: "1.5rem"}}
                            >
                                <MDBIcon icon="plus" className="mr-2"/>
                                Ajouter une formation
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </div>
                <div className="dashboard-content card">
                    <MDBAnimation type="fadeIn">
                        <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
                            <MDBTableHead color="primary">
                                <tr>
                                    <th style={{paddingLeft: "2rem"}}>Nom de la formation</th>
                                    <th>Identifiant RNCP</th>
                                    <th>Nombre de taxe récoltée</th>
                                    <th>Montant total récolté</th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody  >
                                {formationsListDisplay.map((formation, index)=>(
                                    <FormationRow
                                        taList={taList}
                                        formation={formation}
                                        key={index}
                                    />
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBAnimation>
                </div>
            </div>
            <FormationFormDialog
                open={AddingFormationDialogFormOpen}
                editMode={false}
                handleClose={()=>{setAddingFormationDialogFormOpen(false)}}
            />
        </StackdDataContext.Provider>
    )
}