import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MDBCol, MDBRow} from "mdbreact";

import {Checkbox, FormControlLabel} from "@material-ui/core";
import equal from "fast-deep-equal/es6";
import ObjectUtils from "../../utils/ObjectUtils";

const CompanyForm = forwardRef(({companyProp, contactDecisionProp, contactCertificateProp, isEdit, handleSubmit, handleIsValid}, ref) => {
	const [company, setCompany] = useState(companyProp)

	const [isSameContact, setIsSameContact] = useState(contactDecisionProp.contactId === contactCertificateProp.contactId)

	const [contactDecision, setContactDecision] = useState(contactDecisionProp)
	const [contactCertificate, setContactCertificate] = useState(contactCertificateProp)

	const [openDecision, setOpenDecision] = useState(true)
	const [openCertificate, setOpenCertificate] = useState(false)

	useEffect(()=>{
		const notEmptyCompany = !ObjectUtils.hasLestOneProperty(company, {companyName: "", siretNum: "", address: "", postalCode: "", city: ""})
		const notEmptyContactDecision = !ObjectUtils.hasLestOneProperty(contactDecision, {firstname: "", lastname: "", position: "",  email: ""})
		const notEmptyContactCertificate = isSameContact || !ObjectUtils.hasLestOneProperty(contactCertificate, {firstname: "", lastname: "", position: "",  email: ""})

		let localIsValid = notEmptyCompany && notEmptyContactDecision && notEmptyContactCertificate;

		if(isEdit){
			const companyHasBeenChanged = !equal(company, companyProp)
			const contactDecisionHasBeenChanged = !equal(contactDecision, contactDecisionProp)
			const contactCertificateHasBeenChanged = !equal(contactCertificate, contactCertificateProp)

			localIsValid = localIsValid && (companyHasBeenChanged || contactDecisionHasBeenChanged || contactCertificateHasBeenChanged)
		}

		handleIsValid(localIsValid)
	}, [company, contactCertificate, contactDecision, isSameContact, companyProp, contactCertificateProp, contactDecisionProp, isEdit, handleIsValid])

	useImperativeHandle(ref, () => ({
		submit() {
			let companyData = {...company, ...{
				contactIdDecision: contactDecision.contactId,
				contactIdCertificate: isSameContact ? contactDecision.contactId : contactCertificate.contactId
			}}

			console.log("companyData", companyData)

			handleSubmit({
				company: companyData,
				contactDecision: contactDecision,
				contactCertificate: isSameContact ? contactDecision : contactCertificate
			})
		},
		get(name) {
			if(name === "isSameContact")
				return isSameContact

			if(name === "openDecision")
				return openDecision

			if(name === "openCertificate")
				return openCertificate
		}
	}));

	const handleSetIsSameContact = (event) => {
		if(event.target.checked){
			setContactCertificate(contactDecision)
		}

		if(!event.target.checked){
			setContactCertificate({firstname: "", lastname: "", position: "",  email: "", phoneNumber: "", contactId: ""})
		}

		setIsSameContact(event.target.checked)
	}

	const handleCompany = (name) => (event) => {
		setCompany({...company, [name]:event.target.value})
	}

	const handleContactDecision = (name) => (event) => {
		setContactDecision({...contactDecision, [name]:event.target.value})
	}

	const handleContactCertificate = (name) => (event) => {
		setContactCertificate({...contactCertificate, [name]:event.target.value})
	}

	const title = isSameContact ? "Contact décision & attestation" : "Contact décision"

	return (
		<>
			<label htmlFor="COMPANY-NAME" className="mt-2" style={{fontWeight: 500}}>
				<b>Nom de l'entreprise</b>
			</label>
			<input
				required
				value={company.companyName}
				style={{fontWeight: 500}}
				type="text"
				className="form-control mb-2"
				id="input-company-name"
				onChange={handleCompany("companyName")}
			/>

			<label htmlFor="COMPANY-SIRET" className="mt-2" style={{fontWeight: 500}}>
				<b>Numero SIRET</b>
			</label>
			<input
				required
				value={company.siretNum}
				style={{fontWeight: 500}}
				type="text"
				className="form-control mb-2"
				id="input-company-siret"
				onChange={handleCompany("siret")}
			/>

			<label htmlFor="COMPANY-ADDRESSE" className="mt-1" style={{fontWeight: 500}}>
				<b>Adresse</b>
			</label>
			<input
				required
				value={company.address}
				style={{fontWeight: 500}}
				type="text"
				className="form-control mb-2"
				id="input-company-address"
				onChange={handleCompany("address")}
			/>

			<MDBRow>
				<MDBCol size="6">
					<label htmlFor="COMPANY-CITY" className="mt-1" style={{fontWeight: 500}}>
						<b>Ville</b>
					</label>
					<input
						required
						value={company.city}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-3"
						id="input-company-city"
						onChange={handleCompany("city")}
					/>
				</MDBCol>
				<MDBCol size="6">
					<label htmlFor="COMPANY-POSTALCODE" className="mt-1" style={{fontWeight: 500}}>
						<b>Code postal</b>
					</label>
					<input
						required
						value={company.postalCode}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-3"
						id="input-company-postalcode"
						onChange={handleCompany("postalCode")}
					/>
				</MDBCol>
			</MDBRow>

			<FormControlLabel
				className="mt-2"
				control={
					<Checkbox
						checked={isSameContact}
						onChange={handleSetIsSameContact}
						color="primary"
						inputProps={{ 'aria-label': 'checkbox-same-contact' }}
					/>
				}
				label="Contact attestation identique au contact décision"
			/>

			<div className="mt-2" onClick={()=>{setOpenDecision(!openDecision)}} style={{fontSize: "1.2rem", fontWeight: "600"}}>
				{title} {openDecision ? (<ExpandLessIcon/>) : (<ExpandMoreIcon/>)}
			</div>

			<div style={{display: openDecision ? "flex" : "none"}} id="contact-decision">
				<ContactForm contact={contactDecision} handleContactChange={handleContactDecision} name={"decision"}/>
			</div>
			{!isSameContact ? (
				<>
					<div className="mt-2" onClick={()=>{setOpenCertificate(!openCertificate)}} style={{fontSize: "1.2rem", fontWeight: "600"}}>
						Contact attestation {openCertificate ? (<ExpandLessIcon/>) : (<ExpandMoreIcon/>)}
					</div>
					<div style={{display: openCertificate ? "flex" : "none"}} id="contact-certificate">
						<ContactForm contact={contactCertificate} handleContactChange={handleContactCertificate} name={"certificate"}/>
					</div>
				</>
			) : ""}
		</>
	);
});


function ContactForm({contact, handleContactChange, name}){
	return(
		<div className="mt-2">
			<MDBRow>
				<MDBCol size="4">
					<label htmlFor="COMPANY-firstname" className="mt-0" style={{fontWeight: 500}}>
						<b>Prénom</b>
					</label>
					<input
						required
						value={contact.firstname}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-2"
						id={`input-contact-${name}-firstname`}
						onChange={handleContactChange("firstname")}
					/>
				</MDBCol>
				<MDBCol size="4">
					<label htmlFor="COMPANY-lastname" className="mt-0" style={{fontWeight: 500}}>
						<b>Nom</b>
					</label>
					<input
						required
						value={contact.lastname}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-2"
						id={`input-contact-${name}-lastname`}
						onChange={handleContactChange("lastname")}
					/>
				</MDBCol>
				<MDBCol size="4">
					<label htmlFor="COMPANY-position" className="mt-0" style={{fontWeight: 500}}>
						<b>Fonction</b>
					</label>
					<input
						required
						value={contact.position}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-2"
						id={`input-contact-${name}-position`}
						onChange={handleContactChange("position")}
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol size="6">
					<label htmlFor="COMPANY-email" className="mt-0" style={{fontWeight: 500}}>
						<b>Email</b>
					</label>
					<input
						required
						value={contact.email}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-2"
						id={`input-contact-${name}-email`}
						onChange={handleContactChange("email")}
					/>
				</MDBCol>
				<MDBCol size="6">
					<label htmlFor="COMPANY-phoneNumber" className="mt-0" style={{fontWeight: 500}}>
						<b>Numéro de téléphone</b>
					</label>
					<input
						value={contact.phoneNumber}
						style={{fontWeight: 500}}
						type="text"
						className="form-control mb-2"
						id={`input-contact-${name}-phoneNumber`}
						onChange={handleContactChange("phoneNumber")}
					/>
				</MDBCol>
			</MDBRow>
		</div>
	)
}

export default CompanyForm