import React, {useContext, useRef, useState} from 'react';
import equal from  'fast-deep-equal'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import StackdDataContext from "../../context/StackdDataContext";
import CompanyForm from "../form/CompanyForm";

export default function CompanyFormDialog({open, companyProp, contactDecisionProp, contactCertificateProp, handleClose}) {
	const {editCompany, createContact, editContact, deleteContact} = useContext(StackdDataContext)
	const [isValid, setIsValid] = useState(false)
	const companyFormRef = useRef()

	const handleSubmit = async ({company, contactDecision, contactCertificate}) => {
		if(equal(contactCertificate, contactDecision) && contactDecisionProp.contactId !== contactCertificateProp.contactId){
			deleteContact(contactCertificateProp)
		}

		if(!equal(contactCertificate, contactDecision) && contactDecisionProp.contactId === contactCertificateProp.contactId){
			const response = await createContact(contactCertificate, "contactCertificate")
			company.contactIdCertificate = response.data.contactId
		}

		if(!equal(contactDecision, contactDecisionProp)){
			editContact(contactDecision, "contactDecision")
		}

		if(!equal(contactCertificate, contactCertificateProp) && contactDecisionProp.contactId !== contactCertificateProp.contactId){
			editContact(contactCertificate, "contactCertificate")
		}

		editCompany(company)
	}

	const handleIsValid = (isValidLocal) => {setIsValid(isValidLocal)}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Editer une entreprise</DialogTitle>
			<DialogContent style={{
				transition: "height .2s ease-in-out",
				maxHeight: "60vh",
				overflowY: "auto"
			}}>
				<CompanyForm
					ref={companyFormRef}
					handleSubmit={handleSubmit}
					companyProp={companyProp}
					contactDecisionProp={contactDecisionProp}
					contactCertificateProp={contactCertificateProp}
					handleIsValid={handleIsValid}
					isEdit={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => companyFormRef.current.submit()} disabled={!isValid} color="primary">
					Valider
				</Button>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
			</DialogActions>
		</Dialog>
	);
}