import axios from 'axios';
import stackd from "./stackd.api";

const companyApiUrl = "https://entreprise.data.gouv.fr/api/sirene/v3/";

const companyApi = axios.create({baseURL: companyApiUrl});

const isSiretExist = (siret) => {
	function formatGouv(data){
		return{
			companyId: "",
			companyName: data.etablissement.unite_legale.denomination,
			address: data.etablissement.geo_l4,
			postalCode: data.etablissement.code_postal,
			city: data.etablissement.libelle_commune,
			siretNum: siret
		}
	}

	return new Promise(async (resolve, reject) => {
		stackd.get(`/companies/search/isExist?prop=siretNum&&value=${siret}`)
			.then(response=>response.data)
			.then(resolve)
			.catch(async ()=> {
				try {
					const responseGouv = await companyApi.get(`/etablissements/${siret}`);
					resolve(formatGouv(responseGouv.data))
				}
				catch(error){
					reject("Aucune entreprise ne correspond")
				}
			})
	})
}
const isCompanyExist = (companyName) => {
	function formatGouv(data){
		return {
			companyId: "",
			companyName: data.unites_legales[0].denomination,
			address: data.unites_legales[0].etablissement_siege.geo_l4,
			postalCode: data.unites_legales[0].etablissement_siege.code_postal,
			city: data.unites_legales[0].etablissement_siege.libelle_commune,
			siretNum: data.unites_legales[0].etablissement_siege.siret,
		}
	}
	return new Promise(async (resolve, reject)=>{
		stackd.get(`/companies/search/isExist?prop=companyName&&value=${companyName}`)
			.then(response=>response.data)
			.then(resolve)
			.catch(async ()=> {
				try{
					const responseInit = await companyApi.get(`/unites_legales?denomination=${companyName}`);
					resolve(formatGouv(responseInit.data))
				} catch (errorInit) {
					try {
						const responseUppercase = await companyApi.get(`/unites_legales?denomination=${companyName.toUpperCase()}`)
						resolve(formatGouv(responseUppercase.data))
					} catch (errorUppercase) {
						reject("Aucune entreprise ne correspond")
					}
				}
			})
	})
}

export {isCompanyExist, isSiretExist}