import React, {useContext, useState} from 'react';
import {MDBIcon} from 'mdbreact';

import stackd from '../../api/stackd.api';
import ToastContext from "../../context/ToastContext";
import {Button, Typography} from "@material-ui/core";
import FormationFormDialog from "../dialog/FormationFormDialog";
import StackdDataContext from "../../context/StackdDataContext";
import Alert from "../../utils/Alert";

export default function FormationRow({formation, taList}){
    let taListFormation = taList.filter(ta=>ta.formationId === formation.formationId)
    let sumTA = 0;
    taListFormation.forEach((ta)=>{sumTA+=parseFloat(ta.amount)});

    return(
        <tr>
            <td>
                <div className="d-block" style={{maxWidth: "20vw"}}>
                    <Typography noWrap={true}>
                        {formation.formationName}
                    </Typography>
                </div>
            </td>
            <td>{formation.numberRNCP}</td>
            <td>{taListFormation.length}</td>
            <td>{sumTA} €</td>
            <ActionTd formation={formation}/>
        </tr>
    )
}

const ActionTd = ({formation}) => {
    const [open, setOpen] = useState(false)
    const {toast} = useContext(ToastContext)
    const {deleteFormation} = useContext(StackdDataContext)

    const handleDeleteFormation = () => {
        Alert.warningDelete().then(()=>{
            stackd.delete(`/formations/${formation.formationId}`)
                .then(()=>{
                        toast.fire({title: 'Formation supprimée!', icon: "success"})
                        deleteFormation(formation)
                    })
                .catch(Alert.error)
        })
    }

    return(
        <td style={{textAlign: "center"}} className="btn-action">
            <Button size="medium" className="mr-2" onClick={()=>{setOpen(true)}} color="primary">
                <MDBIcon icon="pen-alt" className="mr-2"/>
                Editer
            </Button>
            <Button color="secondary" size="medium" onClick={handleDeleteFormation}>
                <MDBIcon icon="trash-alt" className="mr-2"/>
                Supprimer
            </Button>
            <FormationFormDialog
                formation={formation}
                editMode={true}
                open={open}
                handleClose={()=>{setOpen(false)}}
            />
        </td>
    )
}