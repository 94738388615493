import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import BootstrapInput from "../BootstrapInput";
import StackdDataContext from "../../context/StackdDataContext";

export default function CalculatorFormDialog({open, handleClose, handleCalc}) {
	const [employeeNb, setEmployeeNb] = useState("lessThan250")
	const [msal, setMsal] = useState(0)
	const [dons, setDons] = useState(0)

	const {organisation} = useContext(StackdDataContext)

	const handleSubmit = () => {
		let amountCalc = Math.floor(organisation.percent * ((0.13 * 0.0068 * msal) - dons));
		handleCalc(amountCalc)
		handleClose()
	}

	const year = parseInt(new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(Date.now()));
	const previousYear = year - 1

	return (
		<Dialog
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Calculer le montant du solde de ma taxe d'apprentissage</DialogTitle>
			<DialogContent id="calculator-form-dialog">
				<MDBContainer>
					{organisation.percent === 1 && (
						<>
							<FormLabel component="legend">Nombre de salarié(s)</FormLabel>
							<RadioGroup
								row
								aria-label="moreThan250"
								name="moreThan250"
								value={employeeNb}
								onChange={(event)=>{setEmployeeNb(event.target.value)}}
							>
								<FormControlLabel value={"lessThan250"} control={ <Radio/>} label="Moins de 250 salariés" />
								<FormControlLabel value={"moreThan250"} control={<Radio/>} label="Plus de 250 salariés" />
							</RadioGroup>
						</>
					)}
					<MDBRow className="mt-4">
						<MDBCol size="7">
							{`Montant de la Masse Salariale ${previousYear} de votre entreprise en €`}
						</MDBCol>
						<MDBCol size="5">
							<BootstrapInput
								type="number"
								onChange={(event)=>{setMsal(parseInt(event.target.value))}}
								value={msal}
							/>
						</MDBCol>
					</MDBRow>
					{organisation.percent === 1 && (
						<MDBRow className="mt-4 mb-2">
							<MDBCol size="7">
								{`Déduction des dons en nature effectués du 1er Juin ${previousYear} au 31 mai ${year} ${employeeNb === "moreThan250" ? "et/ou créances CSA" : ""} en €`}
							</MDBCol>
							<MDBCol size="5">
								<BootstrapInput
									type="number"
									onChange={(event)=>{setDons(parseInt(event.target.value))}}
									value={dons}
								/>
							</MDBCol>
						</MDBRow>
					)}
				</MDBContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary">
					Calculer
				</Button>
			</DialogActions>
		</Dialog>
	);
}