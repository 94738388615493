import React, {useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import stackd from '../../api/stackd.api';

import CompanyRow from "../../components/row/CompanyRow";
import Alert from "../../utils/Alert";
import ListUtils from "../../utils/ListUtils";
import FilterSearch from "../../components/FilterSearch";
import FilterSelect from "../../components/FilterSelect";

export default function DashboardCompanies(){
    const [isLoading, setIsLoading] = useState(true);
    const [filterSearch, setFilterSearch] = useState("")
    const [filterState, setFilterState] = useState("All")

    const [companiesListDisplay, setCompaniesListDisplay] = useState([])
    const [taListDisplay, setTaListDisplay] = useState([])

    const [companiesList, setCompaniesList] = useState([])
    const [taList, setTaList] = useState([])

    const yearFormatter = Intl.DateTimeFormat("fr-FR", {year: "numeric"})
    const year = yearFormatter.format(Date.now())

    useEffect(()=>{
        const promisesList = [
            stackd.get('/ta'),
            stackd.get('/companies'),
        ]
        Promise.all(promisesList).then((results)=>{
            setTaList(results[0].data)
            setCompaniesList(results[1].data)
            setIsLoading(false)
        }).catch(Alert.error)
    }, [])
    useEffect(()=>{
        let listCompanies = companiesList
        let listTA = taList

        if(filterState.includes("state")) {
            let state = filterState.split("-");

            state = state.length > 2 ? `${state[1]}-${state[2]}` : state[1]

            listTA = ListUtils.filterValue(taList, "state", state);
            listCompanies = ListUtils.filterValue(listCompanies, "companyId", listTA.map(ta => ta.companyId))
        }

        if(filterState.includes("dateTime")) {
            listTA = taList.filter((ta) => {
                const dateTime = yearFormatter.format(ta.dateTime);
                return dateTime === year
            })
            listCompanies = ListUtils.filterValue(listCompanies, "companyId", listTA.map(ta=>ta.companyId))

            if(filterState.includes("not")){
                listCompanies = companiesList.filter(companyElement => !listCompanies.includes(companyElement))
            }
        }

        if(filterSearch !== "")
            listCompanies = ListUtils.filterSearch(listCompanies, "companyName", filterSearch);

        setTaListDisplay(listTA)
        setCompaniesListDisplay(listCompanies)
    }, [filterSearch, filterState, companiesList, taList, yearFormatter, year])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <div className="dashboard" id="dashboard-company">
            <div className="dashboard-header mb-3">
                <MDBRow>
                    <MDBCol size="6" className="pl-0">
                        <MDBRow>
                            <MDBCol size="7">
                                <FilterSearch
                                    placeholder="Rechercher une entreprise"
                                    value={filterSearch}
                                    handle={setFilterSearch}
                                />
                            </MDBCol>
                            <MDBCol size="5">
                                <FilterSelect
                                    label="Status"
                                    value={filterState}
                                    handle={setFilterState}
                                    options={[
                                        {text: "Tout", value: "All"},
                                        {text: `Promesse enregistrée pour ${year}`, value: `dateTime-${year}`},
                                        {text: `Pas de promesse enregistrée pour ${year}`, value: `not-dateTime-${year}`},
                                        {text: `Promesse versée`, value: "state-Versée"},
                                        {text: `Promesse non versé`, value: "state-Non-versée"},
                                    ]}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="dashboard-content card">
                <MDBAnimation type="fadeIn">
                    <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
                        <MDBTableHead>
                            <tr>
                                <th style={{paddingLeft: "2rem"}}>Nom de l'entreprise</th>
                                <th>Contact (decision)</th>
                                <th>Email (decision)</th>
                                <th>Téléphone (decision)</th>
                                <th>Status du dernier versement</th>
                                <th>Montant du dernier versement (€)</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {companiesListDisplay.map((company, index)=>(
                                <CompanyRow
                                    taList={taListDisplay.filter((ta)=>ta.companyId === company.companyId)}
                                    company={company}
                                    key={index}
                                />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBAnimation>
            </div>
        </div>
    )
}