import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'

import {Button, Fade, Menu, MenuItem} from "@material-ui/core";
import {MDBBadge, MDBCol, MDBIcon, MDBRow, MDBSpinner} from "mdbreact";

import DoneIcon from '@material-ui/icons/Done';
import TimerIcon from '@material-ui/icons/Timer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import stackd from "../../api/stackd.api";
import storage from "../../api/storage.api";

import Alert from "../../utils/Alert";
import ToastContext from "../../context/ToastContext";
import StackdDataContext from "../../context/StackdDataContext";

import TAFormDialog from "../../components/dialog/TAFormDialog";
import TAValidationDialog from "../../components/dialog/TAValidationDialog";

export default function ViewTA(){
    const { taId } = useParams();

    const [openEdit, setOpenEdit] = useState(false)
    const [openValidation, setOpenValidation] = useState(false)

    const [ta, setTa] = useState(null);
    const [formation, setFormation] = useState(null);
    const [company, setCompany] = useState(null);
    const [department, setDepartment] = useState(null)
    const [contactDecision, setContactDecision] = useState(null)
    const [contactAttestation, setContactAttestation] = useState(null)

    const [isPending, setIsPending] = useState(false)
    let history = useHistory()
    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        if(ta !== null)
            return;

        stackd.get(`/ta/${taId}`).then(response => response.data).then(setTa)
    },[taId, ta])

    useEffect(()=>{
        if(ta === null)
            return

        let formationPromise = stackd.get(`/formations/${ta.formationId}`);
        let companyPromise = stackd.get(`/companies/${ta.companyId}`)

        Promise.all([formationPromise, companyPromise]).then((result)=>{
            setFormation(result[0].data)
            setCompany(result[1].data)

            let contactAttestationPromise = stackd.get(`/contacts/${result[1].data.contactIdCertificate}`)
            let contactDecisionPromise = stackd.get(`/contacts/${result[1].data.contactIdDecision}`)
            let departmentPromise = stackd.get(`/departments/${result[0].data.departmentId}`)

            Promise.all([contactAttestationPromise, contactDecisionPromise, departmentPromise]).then((result2)=>{
                setDepartment(result2[2].data)
                setContactAttestation(result2[0].data)
                setContactDecision(result2[1].data)
            });
        })
    }, [ta])

    const handleEditTA = (taChanged) => {
        delete taChanged.companyName

        stackd.patch(`/ta/${ta.taId}`, taChanged).then(()=>{
            if(ta.formationId !== taChanged.formationId && (ta.amount === taChanged.amount && ta.paymentType === taChanged.paymentType))
                toast.fire({title: "Affectation sauvegardée", icon: "success"})
            else
                toast.fire({title: "Modification sauvegardée", icon: "success"})

            setTa(taChanged)
            setOpenEdit(false)
        }).catch(Alert.error)
     }

    const handleValidTA = async (date, chequeNum) => {
        let taChanged = {...ta, ...{
            state: "Versée",
            accountId: sessionStorage.getItem("accountId"),
            dateTimePayment: String(date),
            chequeNum: chequeNum,
            uriPdfCertificate: ""
        }}

        setIsPending(true)
        try{
            await stackd.patch(`/ta/${ta.taId}`, taChanged)
            const {path} = await stackd.post(`/ta/${ta.taId}/generate_pdf`, {pdfType: "Recu_Lib"}).then(response=>response.data)

            setTa({...taChanged, uriPdfCertificate: path})
            setIsPending(false)
            toast.fire({title: "TA Validée", icon: "success"})
            history.push(`/ta/view/${ta.taId}`, {ta: taChanged})
        } catch(error){
            Alert.error(error)
        }
    }

    const handleDeleteTA = () => {
        Alert.warningDelete().then(()=>{
            stackd.delete(`/ta/${taId}`).then(()=>{
                toast.fire({title: "TA supprimée", icon: "success"});
                history.push(`/dashboard/ta`)
            }).catch(Alert.error)
        })
    }

    if(ta === null || contactDecision === null)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <StackdDataContext.Provider value={{handleDeleteTA, handleValidTA, handleEditTA}}>
            <div id="view-ta" className="view-content">
                <MDBRow>
                    <MDBCol size="12">
                        <MainPanel
                            setOpenValidation={setOpenValidation}
                            setOpenEdit={setOpenEdit}
                            ta={ta}
                            company={company}
                            isPending={isPending}
                        />
                        <DetailPanel
                            ta={ta}
                            formation={formation}
                            department={department}
                        />
                        <CompanyPanel
                            company={company}
                            contactCertificate={contactAttestation}
                            contactDecision={contactDecision}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
            <TAFormDialog
                open={openEdit}
                data={ta}
                handleClose={()=>{setOpenEdit(false)}}
            />
            <TAValidationDialog
                isCheque={ta.paymentType === "Chèque"}
                open={openValidation}
                handleClose={()=>{setOpenValidation(false)}}
            />
        </StackdDataContext.Provider>
    )
}

function MainPanel({setOpenValidation, setOpenAssignment, setOpenEdit, ta, company, isPending}){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const years = new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(ta.dateTime)
    const icon = ta.state === "Versée" ? <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem"}}/> : <TimerIcon style={{color: "orange", fontSize: "1rem"}}/>;

    const {handleDeleteTA} = useContext(StackdDataContext)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenPDF = (uriPDF) => event => {
        window.open(`${storage}/${uriPDF}`, '_blank');
        handleClose()
    }

    const pdfDisabled = ta.uriPdfResume === ta.uriPdfCertificate && ta.uriPdfResume === null
    const changeStateDisabled = ta.state === "Versée" || sessionStorage.getItem("permission") !== "Comptable"

    return(
        <MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
            <MDBCol size="6">
                <h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{company.companyName} - {years}</h2>
                <MDBRow>
                    <MDBCol size="2" className="mr-3 pl-3">
                        <span className="mb-2" style={{color: "#646464"}}>Status</span>
                        <MDBBadge color={ta.state === "Versée" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
                            <span style={{marginTop: "2px"}}>{ta.state}</span> {icon}
                        </MDBBadge>
                    </MDBCol>
                    <MDBCol size="4" className="px-0 border-left border-light">
                        <div className="ml-4">
                            <span className="mb-1" style={{color: "#646464"}}>Date de creation</span>
                            <br/>
                            <span style={{marginTop: "0.4rem", display: "block"}}>
                                {new Intl.DateTimeFormat("fr-FR").format(ta.dateTime)}
                            </span>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol size="6">
                <MDBRow className="justify-content-end">
                    <Button className="mr-4" color="primary" id="button-edit-ta" onClick={()=>{setOpenEdit(true)}}>
                        <MDBIcon icon="pen-alt" className="mr-2"/>
                        Editer
                    </Button>
                    <Button color="secondary" id="button-delete-ta" onClick={handleDeleteTA}>
                        <MDBIcon icon="trash-alt" className="mr-2"/>
                        Supprimer
                    </Button>
                </MDBRow>
                <MDBRow className="mt-4 justify-content-end">
                    <Button
                        aria-controls="fade-menu"
                        color="primary"
                        aria-haspopup="true"
                        onClick={handleClick}
                        disabled={pdfDisabled}
                    >
                        Ouvrir fichier(s) PDF
                        <ExpandMoreIcon/>
                    </Button>
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {ta.uriPdfResume !== null && (
                            <MenuItem onClick={handleOpenPDF(ta.uriPdfResume)}>
                                <MDBIcon icon="file-invoice" className="mr-2"/> Résumé TA
                            </MenuItem>
                        )}
                        {ta.uriPdfCertificate !== null && (
                            <MenuItem onClick={handleOpenPDF(ta.uriPdfCertificate)}>
                                <MDBIcon icon="file-contract" className="mr-2"/> Reçu libératoire
                            </MenuItem>
                        )}
                    </Menu>
                    <Button
                        className="badge-success ml-4"
                        disabled={changeStateDisabled || isPending}
                        onClick={()=>{setOpenValidation(true)}}
                    >
                        Valider
                        <DoneIcon className="ml-2" style={{color: "#0e6245", fontSize: "1rem"}}/>
                        {isPending ? (<MDBIcon icon="spinner" className="ml-1" pulse fixed />) : ""}
                    </Button>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    )
}

function DetailPanel({ta, formation, department}){
    return(
        <MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset"}}>
            <h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>Détails</h2>
            <MDBRow>
                <MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
                    <span className="mb-2">Montant</span>
                    <span className="mb-2">Moyen de paiement</span>
                    <span className="mb-2">ID</span>
                    <span className="mb-2">{formation.formationName !== "defaut" ? "Formation" : "Structure"}</span>
                </MDBCol>
                <MDBCol size="3" style={{display: "inline-grid"}}>
                    <span className="mb-2">{ta.amount} €</span>
                    <span className="mb-2">{ta.paymentType}</span>
                    <span className="mb-2">{ta.taId}</span>
                    <span className="mb-2">{formation.formationName !== "defaut" ? formation.formationName : department.departmentName}</span>
                </MDBCol>
                <MDBCol size="4" style={{display: "inline-grid"}}>
                    <span>
                        <u><b>Informations complémentaires</b></u><br/>
                    </span>
                    {ta.additionalInfo}
                </MDBCol>
            </MDBRow>
        </MDBRow>
    )
}

function CompanyPanel({company, contactDecision, contactCertificate}){
    return(
        <MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset"}}>
            <h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>Entreprise</h2>
            <MDBRow>
                <MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
                    <span className="mb-2">Nom</span>
                    <span className="mb-2">ID</span>
                    <span className="mb-2">Siret</span>
                    <span className="mb-2">Adresse</span>
                    <span className="mb-2">Code Postal</span>
                    <span className="mb-2">Ville</span>
                </MDBCol>
                <MDBCol size="3" style={{display: "inline-grid"}}>
                    <span className="mb-2">{company.companyName}</span>
                    <span className="mb-2">{company.companyId}</span>
                    <span className="mb-2">{company.siretNum}</span>
                    <span className="mb-2">{company.address}</span>
                    <span className="mb-2">{company.postalCode}</span>
                    <span className="mb-2">{company.city}</span>
                </MDBCol>
                <MDBCol size="3" style={{display: "inline-grid"}}>
                    <u><b>Contact decision {contactCertificate.contactId === contactDecision.contactId ? " &  attestation" : ""}</b></u>
                    <span className="mb-2">{contactDecision.firstname} {contactDecision.lastname} - {contactDecision.position}</span>
                    <span className="mb-2">{contactDecision.email}</span>
                    <span className="mb-2">{contactDecision.phoneNumber}</span>
                </MDBCol>
                {contactCertificate.contactId !== contactDecision.contactId && (
                    <MDBCol size="3" style={{display: "inline-grid"}}>
                        <u><b>Contact attestation</b></u>
                        <span className="mb-2">{contactCertificate.firstname} {contactCertificate.lastname} - {contactCertificate.position}</span>
                        <span className="mb-2">{contactCertificate.email}</span>
                        <span className="mb-2">{contactCertificate.phoneNumber}</span>
                    </MDBCol>
                )}
            </MDBRow>
        </MDBRow>
    )
}