import React from 'react'
import {MDBCol, MDBRow, MDBSpinner} from 'mdbreact'
import {useParams} from 'react-router-dom'
import {useQuery} from "react-query";

import stackdNewLogo from "../assets/img/stackd-logo-title.png";

import PromiseTAForm from "../components/form/PromiseTAForm";
import stackd from "../api/stackd.api";
import StackdDataContext from "../context/StackdDataContext";
import ErrorBox from "../components/ErrorBox";

export default function PromiseTAPage(){
    const { organisationId } = useParams();

    const {isLoading, error, data: organisation} = useQuery(
        ["get-organisation", organisationId],
        () => stackd.get(`/organisations/${organisationId}`).then(response=>response.data)
    )

    const years = new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(Date.now())

    if(isLoading)
        return <MDBSpinner/>

    if(error)
        return <ErrorBox error={error}/>

    return(
        <section id="promise-ta">
            <MDBRow className="mx-0" style={{height: "100vh"}}>
                <MDBCol size="5" style={{backgroundColor: "white"}}>
                    <h3 style={{color: "#E02020", fontFamily: "Montserrat", marginTop: "5rem", marginLeft: "3rem"}}>
                        <img src={stackdNewLogo} alt="logo-stackd" className="ml-3" width="40%"/>
                    </h3>

                    <div className="text-center" style={{marginTop: "10rem"}}>
                        <h1 style={{fontSize: "3em"}}>Versez le solde de votre taxe d'apprentissage {years}</h1>
                    </div>
                </MDBCol>
                <MDBCol size="7">
                    <StackdDataContext.Provider value={{organisation}}>
                        <PromiseTAForm/>
                    </StackdDataContext.Provider>
                </MDBCol>
            </MDBRow>
        </section>
    )
}

//#promise-ta > div > div.col-5 > div > img