import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

import stackd from "../../api/stackd.api";
import Alert from "../../utils/Alert";
import StackdDataContext from "../../context/StackdDataContext";
import ToastContext from "../../context/ToastContext";

export default function FormationFormDialog({open, formation, editMode, handleClose}) {
	const [numberRNCP, setNumberRNCP] = useState(editMode ? formation.numberRNCP : "")
	const [formationName, setFormationName] = useState(editMode ? formation.formationName : "")
	const [departmentId, setDepartmentId] = useState(editMode ? formation.departmentId : "")

	const {toast} = useContext(ToastContext);
	const {addFormation, editFormation, departmentsList} = useContext(StackdDataContext)

	const handleSubmit = async () => {
		let formationData = {
			divisionName: editMode ? formation.divisionName : "Null",
			formationName: formationName,
			numberRNCP: numberRNCP,
			departmentId: departmentId
		};

		try {
			const promise = editMode ? stackd.patch(`/formations/${formation.formationId}`, formationData) : stackd.post("/formations", formationData)

			const {formationId} = await promise.then(response=>response.data);
			console.log(formationId);

			if(editMode) {
				editFormation(formationData)
				toast.fire({icon: "success", title: `Formation modifiée`})
				handleClose()
				return
			}

			await stackd.post(`/formations/`,{
				formationName : "defaut",
				divisionName : "non-spécifié",
				numberRNCP: "non-spécifié",
				departmentId : departmentId
			})

			addFormation({...formationData, "formationId": formationId});
			toast.fire({icon: "success", title: `Formation ajoutée`})
			handleClose()
		} catch (error) {
			Alert.error(error)
		}
	}
	const isValid = () => {
		if(!editMode)
			return numberRNCP !== "" && formationName !== "" && departmentId !== ""

		return numberRNCP !== "" && formationName !== "" && departmentId !== "" &&
			(numberRNCP !== formation.numberRNCP || formationName !== formation.formationName || departmentId !== formation.departmentId)
	}

	useEffect(()=>{
		if(!editMode && departmentsList.length>0)
			setDepartmentId(departmentsList[0].departmentId)
	}, [departmentsList, editMode])

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{editMode ? "Editer" : "Ajouter"} une formation</DialogTitle>
			<DialogContent>
				<TextField
					onChange={(event)=>{setNumberRNCP(event.target.value)}}
					value={numberRNCP}
					autoFocus
					margin="dense"
					label="Numéro RNCP"
					type="text"
					fullWidth
					className="mb-3"
				/>
				<TextField
					onChange={(event)=>{setFormationName(event.target.value)}}
					value={formationName}
					margin="dense"
					label="Nom de la formation"
					type="text"
					fullWidth
				/>
				<FormControl style={{marginTop: "1rem"}}>
					<InputLabel id="demo-simple-select-label">Composante</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						style={{
							minWidth: "10vw"
						}}
						value={departmentId}
						onChange={(event)=>{setDepartmentId(event.target.value)}}
					>
					{departmentsList.map((department, index)=>(
						<MenuItem value={department.departmentId} key={index}>
							{department.departmentName}
						</MenuItem>
					))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid()}>
					{editMode ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}