class ObjectUtils {
	static hasLestOneProperty(object, comparator){
		for(const prop in comparator){
			if(object[prop] === comparator[prop])
				return true
		}

		return false
	}
}

export default ObjectUtils