import {MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions} from "mdbreact";
import React from "react";

export default function FilterSelect({label, options, handle, value}){
	return(
		<MDBSelect
			label={label}
			id={`input-${label.toLowerCase().replaceAll(" ", "-")}`}
			getValue={(valueSelect)=>{handle(valueSelect[0])}}
			style={{marginBottom: "0.5rem"}}
		>
			<MDBSelectInput selected={label} />
			<MDBSelectOptions>
				{options.map((option, index)=>(
					<MDBSelectOption
						key={index}
						checked={value === option.value}
						value={option.value}
					>
						{option.text}
					</MDBSelectOption>
				))}
			</MDBSelectOptions>
		</MDBSelect>
	)
}